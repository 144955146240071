import { combineReducers } from "redux";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";

// Import custom components
import productReducer from "./products";
import cartReducer from "./cart";
import filtersReducer from "./filters";
import wishlistReducer from "./wishlist";
import compareReducer from "./compare";
import metadataReducer from "./metadata";
import userReducer from "./user";
import walletReducer from "./wallet";
import bankReducer from "./bank";
import bankDetailReducer from "./bankDetail";

const rootReducer = combineReducers({
  data: productReducer,
  cartList: cartReducer,
  filters: filtersReducer,
  wishlist: wishlistReducer,
  compare: compareReducer,
  Intl,
  metadata: metadataReducer,
  user: userReducer,
  wallet: walletReducer,
  banks: bankReducer,
  bankDetail: bankDetailReducer,
});

export default rootReducer;
