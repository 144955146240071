import React, { Component } from "react";
import { withAuthenticated } from "../../components/HOCs/withAuthenticated";

const { PUBLIC_URL } = process.env;
const styles = {
  width: "80%",
  color: ""
};
class FundingSuccess extends Component {
  state = {};

  render() {
    return (
      <div>
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 right-login">
                <div className=" text-center">
                  <img
                    src="https://i.ibb.co/bgJCHc6/undraw-success-factors-fay0-1.png"
                    alt="undraw-success-factors-fay0-1"
                    border="0"
                    style={{ width: styles.width }}
                  />
                  <h4 className="text-center">Successfully funded wallet</h4>
                  <p className="mb-3">
                    Your wallet was successfully funded and you're ready to
                    roll!
                  </p>
                  <div style={{ width: "100%" }}>
                    <a
                      href={`${PUBLIC_URL}/dashboard/orders`}
                      className="btn btn-solid"
                    >
                      See my orders
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withAuthenticated(FundingSuccess, false);
