import {
    FETCH_BANKS,
  } from "../constants/ActionTypes.js";
  
  const initialState = [];
  
  export default function storeReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_BANKS:
        return action.payload;
  
      default:
        return state;
    }
  }
  