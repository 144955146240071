import { PHONE_PATTERN } from "./patternMatchers";

const requiredEmail = [
  {
    name: "email",
    type: "required",
    stateMap: "email"
  },
  {
    name: "email",
    type: "required",
    stateMap: "email"
  }
];

export const registerValidatorSchema = [
  {
    name: "first_name",
    type: "required",
    stateMap: "first_name"
  },
  {
    name: "last_name",
    type: "required",
    stateMap: "last_name"
  },
  {
    name: "password",
    type: "required",
    stateMap: "password"
  },
  {
    name: "phone_number",
    type: "required",
    stateMap: "phone_number"
  },
  {
    name: "gender",
    type: "required",
    stateMap: "gender"
  },
  {
    name: "reg_type_id",
    type: "required",
    stateMap: "reg_type_id"
  },
  {
    name: "state_id",
    type: "required",
    stateMap: "state_id"
  },
  {
    name: "province_id",
    type: "required",
    stateMap: "province_id"
  },
  ...requiredEmail
];

export const loginValidatorSchema = [
  {
    name: "password",
    type: "required",
    stateMap: "password"
  },
  ...requiredEmail
];

export const walletFundingValidatorSchema = [
  {
    name: "amount",
    type: "required",
    stateMap: "amount"
  },
  {
    name: "transaction_type_id",
    type: "required",
    stateMap: "transaction_type_id"
  }
];


export const withdrawalValidatorSchema = [
  {
    name: "amount",
    type: "required",
    stateMap: "amount"
  },
  {
    name: "password",
    type: "required",
    stateMap: "password"
  }
];

export const bankDetailValidatorSchema = [
  {
    name: "bank_id",
    type: "required",
    stateMap: "bank_id"
  },
  {
    name: "account_name",
    type: "required",
    stateMap: "account_name"
  },
  {
    name: "account_number",
    type: "required",
    stateMap: "account_number"
  },
];

export const profileValidatorSchema = [
  ...requiredEmail,
  {
    name: "first_name",
    type: "required",
    stateMap: "first_name"
  },
  {
    name: "last_name",
    type: "required",
    stateMap: "last_name"
  },
  {
    name: "phone_number",
    type: "required",
    stateMap: "phone_number"
  },
  {
    name: "gender",
    type: "required",
    stateMap: "gender"
  }
];

export const passwordResetValidatorSchema = [...requiredEmail];

export const resetPasswordValidatorSchema = [
  {
    name: "password",
    type: "required",
    stateMap: "password"
  }
];

const validationMessages = {
  isEmail: "Please provide a valid email",
  required: "Please fill this field"
};

export const formatErrs = errs => {
  return errs && errs.map(err => validationMessages[err.trim()]).join(". ");
};
