import shop from "../api/shop";
import * as types from "../constants/ActionTypes";
import store from "../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const getAllProducts = (params) => (dispatch, getState, { api }) => {
  dispatch(fetchProductsBegin());
  return api
    .fetchProducts(params)
    .then(api.checkStatus)
    .then(api.toJSON)
    .then((response) => {
      dispatch(receiveProducts(response.results.data));
    })
    .catch(api.errorHandler);
};

const setProductSearchResults = (products) => {
  return {
    type: types.SET_SEARCH_PRODUCTS,
    products,
  };
};

export const searchProducts = (params = {}) => (
  dispatch,
  getState,
  { api }
) => {
  return api
    .searchProducts(params)
    .then(api.checkStatus)
    .then(api.toJSON)
    .then((response) => dispatch(setProductSearchResults(response.result.data)))
    .catch(api.errorHandler);
};
export const fetchSingleProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});

export const addToCart = (product, qty) => (dispatch, getState) => {
  const { cartList, user } = getState();
  const {
    sell_outside_state,
    sell_outside_province,
    state_id,
    province_id,
  } = product.store;

  if (user.profile) {
    if (state_id === user.profile.state_id) {
      if (province_id !== user.profile.province_id) {
        if (!sell_outside_province)
          return toast.warn("Seller is currently not delivering to your city");
      }
    } else {
      if (!sell_outside_state)
        return toast.warn("Seller is currently not delivering to your state");
    }

    if (user.profile.id === product.store.user_id) {
      return toast.warn("You may only add products created by others");
    }
  }

  if (cartList.cart.length && cartList.cart[0].store_id !== product.store_id) {
    return toast.warn("You may only add products from the same seller");
  }

  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));

  window.location.href = `/store/store-${product.store_id}`;
};

export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
  dispatch(removeFromWishlist(product));
};
export const addToCartUnsafe = (product, qty) => ({
  type: types.ADD_TO_CART,
  product,
  qty,
});
export const clearCart = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_CART,
  });
};
export const removeFromCart = (product_id) => (dispatch) => {
  toast.success("Item Removed from Cart");
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id,
  });
};
export const incrementQty = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
};
export const decrementQty = (productId) => (dispatch) => {
  toast.warn("Item Decrement Qty to Cart");

  dispatch({
    type: types.DECREMENT_QTY,
    productId,
  });
};

export const addToWishlist = (product) => (dispatch) => {
  toast.success("Item Added to Wishlist");
  dispatch(addToWishlistUnsafe(product));
};

export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product,
});

export const removeFromWishlist = (product_id) => (dispatch) => {
  toast.error("Item Removed from Wishlist");
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    product_id,
  });
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
  toast.success("Item Added to Compare");
  dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product,
});
export const removeFromCompare = (product_id) => ({
  type: types.REMOVE_FROM_COMPARE,
  product_id,
});

// Filters
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});
export const filterPrice = (value) => ({
  type: types.FILTER_PRICE,
  value,
});
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol,
});

const fetchCountriesAction = (countries) => ({
  type: types.FETCH_COUNTRIES,
  countries,
});

export const fetchCountries = () => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchCountries()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        const { countries, general_settings } = response.result;
        dispatch(fetchCountriesAction(countries));
        dispatch({
          type: types.FETCH_SETTINGS,
          general_settings,
        });
      })
      .catch(api.errorHandler);
  };
};

const fetchCountryStatesAction = (states) => ({
  type: types.FETCH_COUNTRY_STATES,
  states,
});

export const fetchCountryStates = (countryId) => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchStatesByCountry(countryId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(fetchCountryStatesAction(response.result));
      })
      .catch(api.errorHandler);
  };
};

const fetchStateProvincesAction = (provinces) => ({
  type: types.FETCH_STATE_PROVINCES,
  provinces,
});

export const fetchStateProvinces = (stateId) => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchProvincesByState(stateId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(fetchStateProvincesAction(response.result));
      })
      .catch(api.errorHandler);
  };
};

const registerUserAction = (user) => ({
  type: types.REGISTER_USER,
  user,
});

export const registerUser = (user, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .registerUser(user)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(registerUserAction(user));
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const placeOrder = (order, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .placeOrder(order)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const updateUserProfile = (user, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .updateUserProfile(user)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        toast.success("Successfully updated profile");
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

// Banks and Bank Details

export const fetchBanksAction = banks => ({
  type: types.FETCH_BANKS,
  payload: banks
});

export const fetchBanks = () => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchBanks()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchBanksAction(response.result));
      })
      .catch(api.errorHandler);
  };
};


const fetchBankDetailAction = (balance) => ({
  type: types.FETCH_BANK_DETAIL,
  payload: balance
});

export const fetchBankDetail = () => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchBankDetail()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchBankDetailAction(response.result));
      })
      .catch(api.errorHandler);
  };
};

export const updateBankDetail = (data) => {
  return (dispatch, getState, { api }) => {
    return api
      .updateBankDetail(data)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success("Update successful");
        console.log('Error');
      })
      .catch(api.errorHandler);
  };
};


// Wallet and Wallet Transactions
export const fundWallet = (payload, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    return api
      .fundWallet(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        window.location.href = response.authorization_url;
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const fetchWalletTransactionsAction = transactions => ({
  type: types.FETCH_WALLET_TRANSACTIONS,
  payload: transactions
});

const updateWalletBalance = (balance) => ({
  type: types.UPDATE_WALLET_BALANCE,
  payload: balance
});

export const fetchWalletTransactions = () => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchWalletTransactions()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        dispatch(fetchWalletTransactionsAction(response.result));
      })
      .catch(api.errorHandler);
  };
};

export const withdrawFromWallet = (data) => {
  return (dispatch, getState, { api }) => {
    return api
      .withdrawFromWallet(data)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(response => {
        toast.success("Withdrawal successful");
        const { wallet: { transactions }, user: { profile } } = getState();
        dispatch(updateWalletBalance(profile.wallet_balance - response.result.amount));
        dispatch(fetchWalletTransactionsAction([...transactions, response.result]));
      })
      .catch((error) => {
        toast.success(error.response ? error.response.message : error.message);
        api.errorHandler(error);
      });
  };
};

const loginUserAction = (user) => ({
  type: types.LOGIN_USER,
  user,
});

export const loginUser = (user, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .loginUser(user)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(loginUserAction(user));
        localStorage.setItem("token", response.result.token);
        localStorage.setItem("refreshToken", response.result.refreshToken);
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const confirmUser = (token, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .confirmUser(token)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => callback(response))
      .catch(api.errorHandler);
  };
};

export const generatePasswordReset = (payload, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .generatePasswordReset(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => callback(response))
      .catch(api.errorHandler);
  };
};

export const resetPassword = (payload, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .resetPassword(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => callback(response))
      .catch(api.errorHandler);
  };
};

const setUserProfile = (profile) => {
  return {
    type: types.SET_PROFILE,
    profile,
  };
};

export const getUserProfile = (callback) => {
  return (dispatch, getState, { api }) => {
    if (getState().user.profile) return;

    return api
      .getUserProfile()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then(api.setToken)
      .then((response) => {
        dispatch(setUserProfile(response.result[0]));
      })
      .catch((e) => {
        console.log({ e });
        api.errorHandler(e);
        return callback(e);
      });
  };
};

const fetchProductCategoriesAction = (categories) => ({
  type: types.FETCH_PRODUCT_CATEGORIES,
  categories,
});

export const fetchProductCategories = (stateId) => {
  return (dispatch, getState, { api }) => {
    if (getState().metadata.categories.length) return;
    return api
      .fetchProductCategories()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(fetchProductCategoriesAction(response.result));
      })
      .catch(api.errorHandler);
  };
};

const setOrderNotificationsAction = (orderNotifications) => ({
  type: types.FETCH_ORDER_NOTIFICATIONS,
  orderNotifications,
});

export const fetchOrderNotifications = (callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchOrderNotifications()
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(setOrderNotificationsAction(response.results));
      })
      .catch((e) => {
        api.errorHandler(e);
        return callback(e);
      });
  };
};

export const fetchChatMessages = (orderId, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    return api
      .fetchChatMessages(orderId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        return callback(response.results);
      })
      .catch((e) => {
        api.errorHandler(e);
        return callback(e);
      });
  };
};

export const sendChatMessage = (payload, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    return api
      .sendChatMessage(payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        return callback(response.results);
      })
      .catch((e) => {
        api.errorHandler(e);
        return callback(e);
      });
  };
};

export const updateOrder = (
  { orderId, payload, type = "declined" },
  callback
) => {
  return (dispatch, getState, { api }) => {
    return api
      .updateOrder(orderId, payload)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        toast.success(`Successfully ${type} order`);
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

const setOrderAction = (order) => ({
  type: types.FETCH_ORDER,
  order,
});

export const getSingleOrder = (orderId, callback) => {
  return (dispatch, getState, { api }) => {
    if (
      getState().user.orderDetails &&
      getState().user.orderDetails.hasOwnProperty(orderId)
    )
      return;

    return api
      .getSingleOrder(orderId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        dispatch(setOrderAction(response.results[0]));
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const payForOrder = (orderId, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .payForOrder({ order_id: orderId })
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const extendDeliveryTime = (orderId, callback) => {
  if (!window.confirm("Are you sure you want to take this action?")) return;

  return (dispatch, getState, { api }) => {
    return api
      .extendDeliveryTime(orderId, { time: "48H" })
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        toast.success(`Successfully extended delivery time by 24H`);
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const confirmDelivery = (orderId, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .confirmDelivery(orderId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        toast.success(`Successfully confirmed delivery by seller`);
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};

export const requestRefund = (orderId, callback) => {
  return (dispatch, getState, { api }) => {
    return api
      .requestRefund(orderId)
      .then(api.checkStatus)
      .then(api.toJSON)
      .then((response) => {
        toast.success(`Successfully requested a refund`);
        return callback(response);
      })
      .catch(api.errorHandler);
  };
};
