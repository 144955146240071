import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import "./index.scss";

// Import custom components
import store from "./store";
import translations from "./constants/translations";
import { getAllProducts } from "./actions";
// import Landing from './components/landing'

// Layouts
import Default from "./components/layouts/default/main";

//Collection Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import CollectionRightSidebar from "./components/collection/collection-right-sidebar";

// Product Pages
import RightSideBar from "./components/products/right-sidebar";
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
import StoreView from "./components/collection/store";
import Category from "./components/collection/category";
import ColumnRight from "./components/products/column-right";
import Column from "./components/products/column";
import SingleProduct from "./components/products/single-product";

// Features
import Layout from "./components/app";
import Cart from "./components/cart";
import Compare from "./components/compare/index";
import wishList from "./components/wishlist";
import Logout from "./components/logout";
import checkOut from "./components/checkout";
import orderSuccess from "./components/checkout/success-page";
import orderPaymentSuccess from "./components/checkout/order-payment-success-page";

// Extra Pages
import aboutUs from "./components/pages/about-us";
import PageNotFound from "./components/pages/404";
import Success from "./components/pages/success";
import FundingSuccess from "./components/pages/funding-success";
import PasswordResetSent from "./components/pages/password-reset-sent";
import lookbook from "./components/pages/lookbook";
import Login from "./components/pages/login";
import Register from "./components/pages/register";
import ConfirmUser from "./components/pages/confirm-user";
import Search from "./components/pages/search";
import Collection from "./components/pages/collection";
import ForgetPassword from "./components/pages/forget-password";
import ResetPassword from "./components/pages/reset-password";
import Contact from "./components/pages/contact";
import Dashboard from "./components/pages/dashboard";
import Orders from "./components/pages/orders";
import Wallet from "./components/pages/wallet";
import BankDetail from "./components/pages/bankDetail/BankDetail";
import Faq from "./components/pages/faq";
import TermsAndConditions from "./components/pages/terms-and-conditions";
import PaymentVerification from "./components/pages/payment-verification";

class Root extends React.Component {
  render() {
    store.dispatch(getAllProducts());
    const { PUBLIC_URL } = process.env;

    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale="en">
          <BrowserRouter forceRefresh basename={"/"}>
            <ScrollContext>
              <Switch>
                <Layout>
                  {/*Routes For Layouts*/}
                  <Route exact path={`${PUBLIC_URL}/`} component={Default} />
                  <Route
                    path={`${PUBLIC_URL}/products/:id`}
                    component={SingleProduct}
                  />

                  {/*Routes For Features (Product Collection) */}

                  <Route
                    path={`${PUBLIC_URL}/right-sidebar/collection`}
                    component={CollectionRightSidebar}
                  />
                  <Route
                    path={`${PUBLIC_URL}/left-sidebar/collection`}
                    component={CollectionLeftSidebar}
                  />

                  {/*Routes For Single Product*/}
                  <Route
                    path={`${PUBLIC_URL}/col-right/product/:id`}
                    component={ColumnRight}
                  />
                  <Route
                    path={`${PUBLIC_URL}/column/product/:id`}
                    component={Column}
                  />

                  {/*Routes For custom Features*/}
                  <Route path={`${PUBLIC_URL}/cart`} component={Cart} />
                  <Route path={`${PUBLIC_URL}/wishlist`} component={wishList} />
                  <Route path={`${PUBLIC_URL}/compare`} component={Compare} />
                  <Route path={`${PUBLIC_URL}/checkout`} component={checkOut} />
                  <Route
                    path={`${PUBLIC_URL}/order-success`}
                    component={orderSuccess}
                  />
                  <Route
                    path={`${PUBLIC_URL}/order-payment-success`}
                    component={orderPaymentSuccess}
                  />
                  <Route
                    path={`${PUBLIC_URL}/funding-success`}
                    component={FundingSuccess}
                  />
                  <Route
                    path={`${PUBLIC_URL}/password-reset-sent`}
                    component={PasswordResetSent}
                  />

                  <Route
                    path={`${PUBLIC_URL}/sales/orders`}
                    component={aboutUs}
                  />

                  {/*Routes For Extra Pages*/}
                  <Route
                    path={`${PUBLIC_URL}/category/:id`}
                    component={Category}
                  />
                  <Route
                    path={`${PUBLIC_URL}/store/:slug`}
                    component={StoreView}
                  />
                  <Route
                    path={`${PUBLIC_URL}/pages/about-us`}
                    component={aboutUs}
                  />
                  <Route path={`${PUBLIC_URL}/pages/404`} component={Success} />
                  <Route
                    path={`${PUBLIC_URL}/pages/lookbook`}
                    component={lookbook}
                  />
                  <Route path={`${PUBLIC_URL}/login`} exact component={Login} />
                  <Route
                    path={`${PUBLIC_URL}/register`}
                    exact
                    component={Register}
                  />
                  <Route
                    path={`${PUBLIC_URL}/success`}
                    exact
                    component={Success}
                  />
                  <Route
                    path={`${PUBLIC_URL}/forgot-password`}
                    component={ForgetPassword}
                  />
                  <Route path={`${PUBLIC_URL}/logout`} component={Logout} />
                  <Route
                    path={`${PUBLIC_URL}/reset-password/:token`}
                    component={ResetPassword}
                  />

                  <Route
                    path={`${PUBLIC_URL}/auth/confirm/:token`}
                    component={ConfirmUser}
                  />
                  <Route path={`${PUBLIC_URL}/search`} component={Search} />
                  <Route
                    path={`${PUBLIC_URL}/pages/collection`}
                    component={Collection}
                  />
                  <Route
                    path={`${PUBLIC_URL}/pages/contact`}
                    component={Contact}
                  />
                  <Route
                    path={`${PUBLIC_URL}/dashboard`}
                    exact
                    component={Dashboard}
                  />
                  <Route
                    path={`${PUBLIC_URL}/dashboard/orders`}
                    exact
                    component={Orders}
                  />
                  <Route
                    path={`${PUBLIC_URL}/dashboard/wallet`}
                    exact
                    component={Wallet}
                  />
                  <Route
                    path={`${PUBLIC_URL}/dashboard/bank`}
                    exact
                    component={BankDetail}
                  />
                  <Route path={`${PUBLIC_URL}/faq`} component={Faq} />
                  <Route path={`${PUBLIC_URL}/terms-and-conditions`} component={TermsAndConditions} />

                  {/* <Route exact path="*" component={PageNotFound} /> */}
                  
                  <Route
                    path={`${PUBLIC_URL}/payment-verification/:reference`}
                    component={PaymentVerification}
                  />
                </Layout>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
