import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import useForm from "react-valida-hook";
import { ToastContainer, toast } from "react-toastify";
import { generatePasswordReset } from "../../actions";
import {
  passwordResetValidatorSchema,
  formatErrs
} from "../../utils/validators";
import Breadcrumb from "../common/breadcrumb";

const initialState = {
  email: ""
};

function ForgetPassword({ history, generatePasswordReset }) {
  let [formData, validation, validateForm, getData] = useForm(
    initialState,
    passwordResetValidatorSchema
  );

  const handlePasswordReset = e => {
    e.preventDefault();
    let valid = validateForm();

    if (!valid) return;
    generatePasswordReset(getData(), () => {
      toast.success(
        "A password reset link has been sent to your email address."
      );
      setTimeout(function() {
        history.push("/login");
      }, 2000);
    });
  };

  return (
    <div>
      <ToastContainer />
      <Breadcrumb title={"forget password"} />

      {/*Forget Password section*/}
      <section className="pwd-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h2>Forget Your Password</h2>
              <form className="theme-form" onSubmit={handlePasswordReset}>
                <div className="form-row">
                  <div className="col-md-12">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Your Email"
                      required=""
                      {...formData.email.input}
                    />
                    {validation.errors.email && (
                      <p className="text text-danger">
                        {formatErrs(validation.errors.email)}
                      </p>
                    )}
                  </div>
                  <button type="submit" className="btn btn-solid">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  generatePasswordReset: bindActionCreators(generatePasswordReset, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
