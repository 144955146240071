import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  addToCart,
  addToWishlist,
  addToCompare,
  getAllProducts
} from "../../../actions";
import { getVisibleproducts } from "../../../services";
import ProductListItem from "./product-list-item";

class ProductListing extends Component {
  state = {
    limit: 5,
    hasMoreItems: true
  };

  componentWillMount() {
    this.fetchMoreItems();
  }

  fetchMoreItems = () => {
    const { queryParams } = this.props;

    // if (this.state.limit >= this.props.products.length) {
    //   this.setState({ hasMoreItems: false });
    //   return;
    // }

    // async api call
    this.props.fetchCategoryProducts(queryParams, () => {
      this.setState({
        limit: this.state.limit + 5
      });
    });
  };

  render() {
    const {
      products,
      addToCart,
      symbol,
      addToWishlist,
      addToCompare,
      isLoadingProducts
    } = this.props;
    return (
      <div>
        <div className="product-wrapper-grid">
          <div className="container-fluid">
            {isLoadingProducts ? "Loading..." : null}
            {products.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.limit} //This is important field to render the next data
                next={this.fetchMoreItems}
                hasMore={this.state.hasMoreItems}
                loader={<div className="loading-cls"></div>}
                endMessage={
                  <p className="seen-cls seen-it-cls">
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div className="row">
                  {products.slice(0, this.state.limit).map((product, index) => (
                    <div
                      className={`${
                        this.props.colSize === 3
                          ? "col-xl-3 col-md-6 col-grid-box"
                          : "col-lg-" + this.props.colSize
                      }`}
                      key={product.id}
                    >
                      <ProductListItem
                        product={product}
                        symbol={symbol}
                        onAddToCompareClicked={() => addToCompare(product)}
                        onAddToWishlistClicked={() => addToWishlist(product)}
                        onAddToCartClicked={(product, qty) =>
                          addToCart(product, qty)
                        }
                        key={index}
                      />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                    className="img-fluid mb-4"
                  />
                  <h3>
                    Sorry! Couldn't find the product you were looking For!!!{" "}
                  </h3>
                  <p>
                    Please check if you have misspelt something or try searching
                    with other words.
                  </p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-solid"
                  >
                    continue shopping
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  products: getVisibleproducts(state.data, state.filters),
  //products: state.data.products,
  isLoadingProducts: false,
  symbol: state.data.symbol
});

const mapDispatchToProps = dispatch => ({
  addToCart: bindActionCreators(addToCart, dispatch),
  addToWishlist,
  addToCompare,
  fetchCategoryProducts: params => {
    dispatch(getAllProducts(params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
