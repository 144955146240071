import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { addToCart, addToWishlist, addToCompare } from "../../../actions/index";
import ProductItem from "./product-item";

class SpecialProducts extends Component {
  render() {
    const {
      bestSeller,
      symbol,
      addToCart,
      addToWishlist,
      addToCompare
    } = this.props;
    return (
      <div>
        <div className="title1 section-t-space">
          <h4>exclusive products</h4>
          <h2 className="title-inner1">special products</h2>
        </div>
        <section className="section-b-space p-t-0">
          <div className="container">
            <Tabs className="theme-tab">
              <TabList className="tabs tab-title">
                <Tab>Recently Added</Tab>
              </TabList>

              <TabPanel>
                <div className="no-slider row">
                  {bestSeller.map((product, index) => (
                    <ProductItem
                      product={product}
                      symbol={symbol}
                      onAddToCompareClicked={() => addToCompare(product)}
                      onAddToWishlistClicked={() => addToWishlist(product)}
                      onAddToCartClicked={(product, qty) =>
                        addToCart(product, qty)
                      }
                      key={index}
                    />
                  ))}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bestSeller: state.data.products,
  symbol: state.data.symbol
});

export default connect(mapStateToProps, dispatch => ({
  addToCart: bindActionCreators(addToCart, dispatch),
  addToWishlist,
  addToCompare
}))(SpecialProducts);
