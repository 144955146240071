/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import {
  updateUserProfile,
  fundWallet,
  fetchCountries,
  fetchCountryStates,
  fetchStateProvinces
} from "../../actions";
import useForm from "react-valida-hook";
import { profileValidatorSchema, formatErrs } from "../../utils/validators";

import { WalletFundingForm } from "../common/walletFunding";
import Breadcrumb from "../common/breadcrumb";
// Higher Order Components
import { withAuthenticated } from "../HOCs/withAuthenticated";

const CountriesField = ({ countryId, countries = [], onChange, errors }) => {
  let countriesList = countries
    // .filter(({ name }) => name.toLowerCase() === "nigeria")
    .map((country, key) => (
      <option value={country.id} key={key}>
        {country.name}
      </option>
    ));

  return (
    <div className="form-row mb-2">
      <div className="col-md-6">
        <label htmlFor="state_id">Country</label>
        <select
          className="form-control"
          value={countryId}
          onChange={onChange}
          id="country_id"
          name="country_id"
          required
        >
          <option value="0">Choose country</option>
          {countriesList}
        </select>
        {errors.country_id && (
          <p className="text text-danger">{formatErrs(errors.country_id)}</p>
        )}
      </div>
    </div>
  );
};

const StatesField = ({ stateId, states, onChange, errors }) => {
  let statesList = states.map((state, key) => (
    <option value={state.id} key={key}>
      {state.state_label}
    </option>
  ));

  return (
    <div className="form-row mb-2">
      <div className="col-md-6">
        <label htmlFor="state_id">State</label>
        <select
          className="form-control"
          value={stateId}
          onChange={onChange}
          id="state_id"
          name="state_id"
          required
        >
          <option value="0">Choose state</option>
          {statesList}
        </select>
        {errors.state_id && (
          <p className="text text-danger">{formatErrs(errors.state_id)}</p>
        )}
      </div>
    </div>
  );
};

const ProvincesField = ({
  provinces,
  provinceId,
  stateId,
  errors,
  ...props
}) => {
  let provinceList = provinces.map((province, key) => (
    <option key={key} value={province.id}>
      {province.province_label}
    </option>
  ));
  let showField = provinces.length && stateId;

  return (
    <div className={`form-row collapse ${showField && "show mb-2"}`}>
      <div className="col-md-6">
        <label htmlFor="province_id">LGA</label>
        <select
          className="form-control"
          {...props}
          id="province_id"
          name="province_id"
          required
        >
          <option value="0">Choose LGA</option>
          {provinceList}
        </select>
        {errors.province_id && (
          <p className="text text-danger">{formatErrs(errors.province_id)}</p>
        )}
      </div>
    </div>
  );
};

function ProfileForm({
  history,
  updateUserProfile,
  profile,
  countries,
  states,
  provinces,
  fetchCountries,
  fetchCountryStates,
  fetchStateProvinces,
  isOpen,
  onClose
}) {
  const { first_name, last_name, email, phone_number, gender, country_id, state_id, province_id } = profile;
  const initialState = {
    first_name,
    last_name,
    email,
    phone_number: `${phone_number}`,
    gender,
    country_id,
    state_id,
    province_id
  };
  
  const [stateId, setStateId] = React.useState(state_id);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [countryId, setCountryId] = React.useState(country_id);
  const [isProcessing, setProcessing] = React.useState(false);

  let [formData, validation, validateForm, getData] = useForm(
    initialState,
    profileValidatorSchema
  );

  React.useEffect(() => {
    fetchCountries();

    if (countryId) {
      fetchCountryStates(countryId);
    }
  }, [countryId]);

  React.useEffect(() => {
    fetchCountries();

    if (stateId) {
      fetchStateProvinces(stateId);
    }
  }, [stateId]);

  function handleProfileUpdate(e) {
    e.preventDefault();
    setProcessing(true);

    const valid = validateForm();
    if (!valid) return;
    updateUserProfile(getData(), function(response) {
      setTimeout(function() {
        setProcessing(false);

        history.push("/login");
      }, 2000);
    });
  }

  return (
    <>
      <Modal
        styles={{
          modal: {
            minWidth: "90%"
          }
        }}
        open={isOpen}
        onClose={onClose}
      >
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-sm-6 mx-auto">
                <h3>Update profile</h3>
                <div className="theme-card">
                  <form
                    className="theme-form mx-auto"
                    method="post"
                    onSubmit={handleProfileUpdate}
                    noValidate
                  >
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="first_name"
                          name="first_name"
                          {...formData.first_name.input}
                        />
                        {validation.errors.first_name && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.first_name)}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="review">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="last_name"
                          name="last_name"
                          {...formData.last_name.input}
                        />
                        {validation.errors.last_name && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.last_name)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="email">Email</label>
                        <input
                          className="form-control"
                          {...formData.email.input}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="phone_number">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone_number"
                          name="phone_number"
                          required=""
                          {...formData.phone_number.input}
                        />
                        {validation.errors.phone_number && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.phone_number)}
                          </p>
                        )}
                      </div>
                    </div>

                    <CountriesField
                      countries={countries}
                      countryId={countryId}
                      errors={validation.errors}
                      onChange={e => {
                        const { value } = e.target;
                        setCountryId(value);
                        setStateId(0);
                        formData.country_id.input.onChange(e);
                      }}
                    />
                    <StatesField
                      states={states}
                      stateId={stateId}
                      errors={validation.errors}
                      onChange={e => {
                        const { value } = e.target;
                        setStateId(value);
                        fetchStateProvinces(value);
                        formData.state_id.input.onChange(e);
                      }}
                    />
                    <ProvincesField
                      {...formData.province_id.input}
                      provinces={provinces}
                      stateId={stateId}
                      errors={validation.errors}
                    />

                    <div className="form-row mt-3">
                      <div className="col-md-12">
                        <label htmlFor="gender">Gender</label>
                        <div className="d-flex">
                          <div className="custom-control custom-radio mr-3">
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              {...formData.gender.input}
                              value="male"
                              checked={formData.gender.input.value === "male"}
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="male"
                            >
                              Male
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              className="custom-control-input"
                              {...formData.gender.input}
                              value="female"
                              checked={formData.gender.input.value === "female"}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="female"
                            >
                              Female
                            </label>
                          </div>
                        </div>
                        {validation.errors.gender && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.gender)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-row mt-2">
                      <div className="col-md-6">
                        <button
                          type="submit"
                          className="btn btn-solid"
                          disabled={isProcessing}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

function Dashboard(props) {
  const { history, user, fundWallet, symbol, countries, states, provinces } = props;
  const {
    first_name,
    last_name,
    email,
    state_label,
    province_label,
    wallet_balance
  } = user.profile || {};
  const [isProfileUpdateModalOpen, setProfileModalOpen] = React.useState(false);
  const [isWalletFundingModalOpen, setWalletFundingModalOpen] = React.useState(
    false
  );

  return (
    <div>
      <Breadcrumb title={"Dashboard"} />

      {/*Dashboard section*/}
      {user.profile && (
        <>
          <WalletFundingForm
            onClose={() => setWalletFundingModalOpen(false)}
            fundWallet={fundWallet}
            isOpen={isWalletFundingModalOpen}
            redirectUrl="funding-success"
          />
          <ProfileForm
            history={history}
            profile={user.profile}
            countries={countries}
            states={states}
            provinces={provinces}
            fetchCountries={props.fetchCountries}
            fetchCountryStates={props.fetchCountryStates}
            fetchStateProvinces={props.fetchStateProvinces}
            updateUserProfile={props.updateUserProfile}
            isOpen={isProfileUpdateModalOpen}
            onClose={() => setProfileModalOpen(false)}
          />
          <section className="section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="account-sidebar">
                    <a className="popup-btn">my account</a>
                  </div>
                  <div className="card py-4 mb-3">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          href={`${process.env.PUBLIC_URL}/dashboard`}
                          className="nav-link"
                        >
                          Dashboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href={`${process.env.PUBLIC_URL}/dashboard/orders`}
                          className="nav-link"
                        >
                          My Orders
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href={`${process.env.PUBLIC_URL}/dashboard/wallet`}
                          className="nav-link"
                        >
                          Wallet
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href={`${process.env.ADMIN_APP_URL}/dashboard`}
                          className="nav-link"
                        >
                          Seller Area
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href={`${process.env.PUBLIC_URL}/logout`}
                          className="nav-link"
                        >
                          Log out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="dashboard-right">
                    <div className="dashboard">
                      <div className="page-title">
                        <h2>My Dashboard</h2>
                      </div>
                      <div className="welcome-msg">
                        <p>
                          Hello,{" "}
                          <b>
                            {first_name} {last_name}!
                          </b>
                        </p>
                        <p>
                          From your account Dashboard you have the ability to
                          view a snapshot of your recent account activity and
                          update your account information. Select a link below
                          to view or edit information.
                        </p>
                      </div>
                      <div className="box-account box-info">
                        <div className="box-head">
                          <h2 className="mb-3">Account Information</h2>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="card mb-4">
                              <div className="card-body">
                                <h4 className="card-title text-bold">
                                  Your Information
                                  <span className="pull-right bold-text">
                                    <a
                                      href="#"
                                      onClick={e => {
                                        e.preventDefault();
                                        setProfileModalOpen(true);
                                      }}
                                    >
                                      Edit
                                    </a>
                                  </span>
                                </h4>
                                <h6 className="card-subtitle mb-4 text-muted">
                                  <a
                                    
                                    className="mr-4"
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault();
                                      setWalletFundingModalOpen(true);
                                    }}
                                  >
                                    Fund wallet
                                  </a>
                                </h6>
                                <ul className="list-group list-group-flushed mb-4">
                                  <li className="list-group-item">
                                    <div className="row">
                                      <div className="col-6">
                                        <b>Wallet Balance</b>
                                      </div>
                                      <div className="">
                                        <span className="badge badge-info">
                                          <b className="h6">
                                            {symbol}
                                            {wallet_balance}
                                          </b>
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="list-group-item">
                                    <div className="row">
                                      <div className="col-6">Email</div>
                                      <div className="">{email}</div>
                                    </div>
                                  </li>
                                  <li className="list-group-item">
                                    <div className="row">
                                      <div className="col-6">State</div>
                                      <div className="">{state_label}</div>
                                    </div>
                                  </li>
                                  <li className="list-group-item">
                                    <div className="row">
                                      <div className="col-6">City</div>
                                      <div className="">{province_label}</div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    symbol: state.data.symbol,
    countries: state.metadata.countries,
    states: state.metadata.states,
    provinces: state.metadata.provinces,
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserProfile: bindActionCreators(updateUserProfile, dispatch),
  fundWallet: bindActionCreators(fundWallet, dispatch),
  fetchCountries: bindActionCreators(fetchCountries, dispatch),
  fetchCountryStates: bindActionCreators(fetchCountryStates, dispatch),
  fetchStateProvinces: bindActionCreators(fetchStateProvinces, dispatch),
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
