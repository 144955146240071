/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";

class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: "InStock",
      quantity: 1,
      image: "",
      color: "",
      size: ""
    };
  }

  onOpenModal = e => {
    e.preventDefault();
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.qty >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = e => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  componentDidMount() {
    const { product } = this.props;

    this.setState({
      quantity: product.stock,
      image:
        "main_product_images" in product && product.main_product_images.length
          ? product.main_product_images[0]
          : ""
    });
  }

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked
    } = this.props;
    const { open, quantity, color, size } = this.state;
    const rating = product.rating || 3;

    let RatingStars = [];
    for (var i = 0; i < rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i}></i>);
    }

    return (
      <div className="product-box">
        <div className="img-wrapper">
          <div className="front">
            <a
              href={`${process.env.PUBLIC_URL}/products/${product.id}`}
              onClick={this.onOpenModal}
            >
              <img
                src={
                  "main_product_images" in product &&
                  product.main_product_images.length
                    ? product.main_product_images[0].image_url
                    : null
                }
                className="img-fluid"
                alt=""
              />
            </a>
          </div>
          <div className="cart-info cart-wrap">
            <button
              title="Add to cart"
              onClick={() =>
                onAddToCartClicked(
                  {
                    ...product,
                    color: this.state.color,
                    size: this.state.size
                  },
                  quantity
                )
              }
            >
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
            </button>
            <a
              href="javascript:void(0)"
              title="Add to Wishlist"
              onClick={onAddToWishlistClicked}
            >
              <i className="fa fa-heart" aria-hidden="true"></i>
            </a>
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#quick-view"
              title="Quick View"
              onClick={this.onOpenModal}
            >
              <i className="fa fa-search" aria-hidden="true"></i>
            </a>
            <Link
              to={`${process.env.PUBLIC_URL}/compare`}
              title="Compare"
              onClick={onAddToCompareClicked}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </Link>
          </div>
          {"main_product_images" in product &&
          product.main_product_images.length ? (
            <ul className="product-thumb-list">
              {product.main_product_images.map(({ image_url }, i) => (
                <li
                  className={`grid_thumb_img ${
                    image_url === this.state.image ? "active" : ""
                  }`}
                  key={i}
                >
                  <a>
                    <img
                      alt=""
                      src={`${image_url}`}
                      onClick={() => this.onClickHandle(image_url)}
                    />
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
        <div className="product-detail">
          <div>
            {/* <div className="rating">
                            {RatingStars}
                        </div> */}
            <Link to={`${process.env.PUBLIC_URL}/products/${product.id}`}>
              <h6>{product.product_name}</h6>
            </Link>
            <h4>
              {symbol}
              {product.discount_price}
              {product.discount > 0 && (
                <del>
                  <span className="money">
                    {symbol}
                    {product.price}
                  </span>
                </del>
              )}
            </h4>
            {product.colors ? (
              <ul className="color-variant">
                {product.colors.map((vari, i) => {
                  return (
                    <li
                      style={{
                        borderRadius: "100%",
                        border:
                          vari.color !== this.state.color
                            ? "1px solid #ccc"
                            : "5px solid #999",
                        background: vari.color
                      }}
                      key={i}
                      title={vari.color}
                      onClick={() => this.setState({ color: vari.color })}
                    ></li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal open={open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6  col-xs-12">
                    <div className="quick-view-img">
                      {/* <img src={product.variants?
                                                this.state.image?this.state.image:product.variants[0].images
                                                :product.pictures[0]} alt="" className="img-fluid"
                                                /> */}
                    </div>
                  </div>
                  <div className="col-lg-6 rtl-text">
                    <div className="product-right">
                      <h2> {product.product_name} </h2>
                      <h3>
                        {symbol}
                        {product.price}
                        {/* <del><span className="money">{symbol}{product.price}</span></del> */}
                      </h3>
                      {product.colors ? (
                        <ul className="color-variant">
                          {product.colors.map((vari, i) => (
                            <li
                              style={{ background: vari.color }}
                              key={i}
                              title={vari.color}
                              onClick={() => this.onClickHandle(vari.images)}
                            ></li>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                      <div className="border-product">
                        <h6 className="product-title">product details</h6>
                        <p>{product.shortDetails}</p>
                      </div>
                      <div className="product-description border-product">
                        {product.sizes ? (
                          <div className="size-box">
                            <ul>
                              {product.sizes.map(({ size }, i) => {
                                return (
                                  <li key={i} className="mr-1">
                                    <a
                                      href="#"
                                      className="d-flex p-2 align-items-center justify-content-center"
                                      style={{
                                        height: "30px",
                                        border:
                                          size !== this.state.size
                                            ? "1px solid #ccc"
                                            : "2px solid #999"
                                      }}
                                      onClick={e => {
                                        e.preventDefault();
                                        this.setState({ size });
                                      }}
                                    >
                                      {size}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                        <h6 className="product-title">quantity</h6>
                        <div className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-left-minus"
                                onClick={this.minusQty}
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-angle-left"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.quantity}
                              onChange={this.changeQty}
                              className="form-control input-number"
                            />
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-right-plus"
                                onClick={this.plusQty}
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-angle-right"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="product-buttons">
                        <button
                          className="btn btn-solid"
                          onClick={() =>
                            onAddToCartClicked(
                              { ...product, color, size },
                              this.state.quantity
                            )
                          }
                        >
                          add to cart
                        </button>
                        <Link
                          to={`${process.env.PUBLIC_URL}/products/${product.id}`}
                          className="btn btn-solid"
                        >
                          view detail
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ProductListItem;
