import fetch from "isomorphic-fetch";
import { toast } from "react-toastify";
let qs = require("qs");

const { API_URL } = process.env;

/**
 * Prefix a url with the correct api
 * @param {string} url - url to prefix
 */
function prefixHostAddress(url) {
  const hostAddress = API_URL;

  return `${hostAddress}/api${url}`;
}

function getToken() {
  const token = window.localStorage.getItem("token");
  return token;
}

/**
 * Creates a user http request.
 */
function registerUser(user) {
  return fetch(prefixHostAddress("/v1/Auth/Register"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  });
}

/**
 * Creates a user http request.
 */
function loginUser(user) {
  return fetch(prefixHostAddress("/v1/Auth/Authenticate"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  });
}

/**
 * Creates a user http request.
 */
function confirmUser(token) {
  return fetch(prefixHostAddress(`/v1/Auth/Confirm/${token}`), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

/**
 * Creates a recover account http request.
 */
function generatePasswordReset(payload) {
  return fetch(prefixHostAddress(`/v1/Password/SendPasswordResetLink`), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Creates a recover account http request.
 */
function resetPassword(payload) {
  return fetch(prefixHostAddress(`/v1/Password/PasswordReset`), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Creates a user http request.
 */
function getUserProfile() {
  return fetch(prefixHostAddress("/v1/Profile/Info"), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates a recover account http request.
 */
function updateUserProfile(payload) {
  return fetch(prefixHostAddress(`/v1/EditProfile/Info`), {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}


/**
 * Retrieves a list of available banks
 */
function fetchBanks() {
  return fetch(`${API_URL}/api/v1/banks`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Retrieves the bank details of the currently loggged in user
 */
function fetchBankDetail() {
  return fetch(`${API_URL}/api/v1/bank-details`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Updates the bank detail of the logged in user
 * @param {object} payload - an object containing the user's bank details
 * @return {Promise<object>}
 */
function updateBankDetail(payload) {
  return fetch(`${API_URL}/api/v1/bank-details`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Retrieves a list of transactions on the wallet of a user.
 */
function fetchWalletTransactions() {
  return fetch(`${API_URL}/api/v1/wallets/transactions`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Withdraws from a wallet.
 * @param {object} payload - an object containing data for the withdraw
 * @return {Promise<object>}
 */
function withdrawFromWallet(payload) {
  return fetch(`${API_URL}/api/v1/wallets/withdraw`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: payload
  });
}


/**
 * Creates a user http request.
 */
function fundWallet(payload) {
  return fetch(prefixHostAddress(`/v1/Paystack/Pay`), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Creates a user http request.
 */
function placeOrder(payload) {
  return fetch(prefixHostAddress(`/v1/Orders`), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Retrieves all messages in chat.
 */
function fetchChatMessages(orderId) {
  return fetch(prefixHostAddress(`/v1/chat/${orderId}`), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Retrieves all messages in chat.
 */
function sendChatMessage(payload) {
  return fetch(prefixHostAddress(`/v1/chat/`), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Creates a countries http request.
 */
function fetchCountries() {
  return fetch(prefixHostAddress(`/v1/Metadata`), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

/**
 * Creates a provinces http request.
 */
function fetchProvincesByState(stateId) {
  return fetch(prefixHostAddress(`/v1/Metadata/Provinces/${stateId}`), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

/**
 * Creates a user http request.
 */
function fetchStatesByCountry(countryId) {
  return fetch(prefixHostAddress(`/v1/MetaData/States/${countryId}`), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

/**
 * Creates http request for fetching product categories.
 * @return {Promise<object>}
 */
function fetchProductCategories() {
  return fetch(`${API_URL}/api/v1/Product/categories`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates http request for fetching product categories.
 * @return {Promise<object>}
 */
function fetchProducts(params) {
  return fetch(`${API_URL}/api/v1/Products?${qs.stringify(params)}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Creates http request for searching products.
 * @return {Promise<object>}
 */
function searchProducts(params = {}) {
  return fetch(`${API_URL}/api/v1/search?${qs.stringify(params)}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Retrieves order notifications for a user.
 */
function fetchOrderNotifications() {
  return fetch(`${API_URL}/api/v1/buyer/orders`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Update information for an order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function updateOrder(orderId, payload) {
  return fetch(`${API_URL}/api/v1/orders/${orderId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Pay for an order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function payForOrder(payload) {
  return fetch(`${API_URL}/api/v1/order/pay`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(payload)
  });
}

/**
 * Extend the delivery time for an order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function extendDeliveryTime(orderId) {
  return fetch(`${API_URL}/api/v1/order/extendTime/${orderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Confirm receipt of an order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function confirmDelivery(orderId, payload) {
  return fetch(`${API_URL}/api/v1/order/confirmDelivery/${orderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Checks the status of a request.
 * @param {Object} response
 */
async function errorHandler(response) {
  if (response.statusText) {
    let json = await response.json();
    const { status_code } = json;

    if (parseInt(status_code) === 401) {
      // Perhaps clear the auth data in local storage ?
      return;
    }

    if (parseInt(status_code) >= 200 && parseInt(status_code) < 500) {
      // console.log(json.message)
      return toast.error(json.message);
    }

    return toast.error('Something went wrong. Please, try again');
  }
}

/**
 * Resolves or rejects a Promise for an issued response status code.
 *
 * @param {Object} response
 * @return {Object<Promise>}
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 400) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}

/**
 * Fetches a single order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function getSingleOrder(orderId) {
  return fetch(`${API_URL}/api/v1/orders/${orderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Requests a refund for a buyer doing a single order.
 * @param {number} orderId
 * @param {object} payload
 *
 * @return {Promise<object>}
 */
function requestRefund(orderId) {
  return fetch(`${API_URL}/api/v1/orders/${orderId}/processRefund`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  });
}

/**
 * Verifies the status of a payment
 * 
 * @param { string } reference - A payment reference
 */
 function verifyPayment(reference) {
  return fetch(`${API_URL}/api/v1/payments/verify/${reference}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

const toText = response => response.text();
const toJSON = async response => await response.json();

export default {
  registerUser,
  loginUser,
  confirmUser,
  getUserProfile,
  updateUserProfile,
  fetchBanks,
  fetchBankDetail,
  updateBankDetail,
  fundWallet,
  verifyPayment,
  fetchWalletTransactions,
  withdrawFromWallet,
  placeOrder,
  updateOrder,
  payForOrder,
  extendDeliveryTime,
  confirmDelivery,
  fetchChatMessages,
  sendChatMessage,
  generatePasswordReset,
  fetchProducts,
  fetchCountries,
  resetPassword,
  requestRefund,
  searchProducts,
  fetchStatesByCountry,
  fetchProvincesByState,
  fetchProductCategories,
  fetchOrderNotifications,
  getSingleOrder,
  toText,
  toJSON,
  errorHandler,
  checkStatus
};
