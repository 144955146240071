import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { confirmUser } from "../../actions";
import { ToastContainer, toast } from "react-toastify";
import { withAuthenticated } from "../../components/HOCs/withAuthenticated";

const { PUBLIC_URL } = process.env;
const styles = {
  width: "80%",
  color: ""
};

function ConfirmUser({ confirmUser, match, history }) {
  const [hasConfirmedUser, setConfirmedUser] = React.useState(false);

  React.useEffect(() => {
    confirmUser(match.params.token, function() {
      setConfirmedUser(true);
      toast.success("Successfully confirmed registration.");
    });
  }, [match.params]);
  return (
    <div>
      <ToastContainer />
      <div>
        {hasConfirmedUser ? (
          <section className="login-page section-b-space">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 right-login">
                  <div className=" text-center">
                    <img
                      src="https://i.ibb.co/kmxyFgT/undraw-confirmation-2uy0.png"
                      alt="undraw-confirmation-2uy0"
                      border="0"
                      style={{ width: styles.width }}
                    />
                    <h4 className="text-center">
                      Successfully verified account
                    </h4>
                    <p className="mb-3">
                      Your account was successfully verified and you're ready to
                      get more.
                    </p>
                    <div style={{ width: "100%" }}>
                      <a href={`${PUBLIC_URL}/login`} className="btn btn-solid">
                        Go to Login
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="login-page section-b-space">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 right-login">
                  <div className=" text-center">
                    <h4 className="text-center">Verifying account...</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  confirmUser: bindActionCreators(confirmUser, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUser);
