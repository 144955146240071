import React, { Component } from "react";

const styles = {
  width: "80%",
  color: ""
};
class PasswordResetSent extends Component {
  state = {};

  render() {
    return (
      <div>
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 right-login">
                <div className=" text-center">
                  <img
                    src="https://i.ibb.co/bgJCHc6/undraw-success-factors-fay0-1.png"
                    alt="undraw-success-factors-fay0-1"
                    border="0"
                    style={{ width: styles.width }}
                  />
                  <h4 className="text-center">Password Reset Link Sent.</h4>
                  <p className="mb-3">
                    A password reset link has been sent to your email address.
                    Please follow it to reset your password.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PasswordResetSent;
