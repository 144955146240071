import React, { Component } from "react";
import { withAuthenticated } from "../HOCs/withAuthenticated";
const { PUBLIC_URL } = process.env;

class OrderPaymentSuccess extends Component {
  render() {
    const { payment, orderCode = "34ddsw" } = this.props.location.state || {};

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    var current = new Date();
    var next5days = new Date(Date.now() + 5 * 86400000);
    let CheckDate = current.toLocaleDateString("en-US", options).toString();
    let deliveryDate = next5days
      .toLocaleDateString("en-US", options)
      .toString();

    return payment ? (
      <div>
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="success-text">
                  <i className="fa fa-check-circle" aria-hidden="true"></i>
                  <h2>Order payment success</h2>
                  <p>
                    You have successfully paid for your order with code:{" "}
                    {orderCode}
                  </p>
                  <a
                    href={`${PUBLIC_URL}/dashboard/orders`}
                    className="btn btn-dark"
                  >
                    See your order
                  </a>
                  <p>
                    {/* Transaction ID:{" "} */}
                    {/* {payment.paymentID ? payment.paymentID : payment.id} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row order-success-sec mb-3">
                  <div className="col-sm-12">
                    <h4>Summary</h4>
                    <ul className="order-detail">
                      <li>
                        <b>Date of Payment: </b> {CheckDate}
                      </li>
                      <li>
                        <b>Order Code</b>: {orderCode}
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-12 sr-only">
                    <div className="delivery-sec">
                      <h4>Expected date of delivery</h4>
                      <h2>{deliveryDate}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ) : (
      <section className="p-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="error-section">
                <h1>404</h1>
                <h2>page not found</h2>
                <a href="index.html" className="btn btn-solid">
                  back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withAuthenticated(OrderPaymentSuccess, true);
