import React, { Component } from "react";
import { connect } from "react-redux";

class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem("token");
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }

  render() {
    return <>Logging out...</>;
  }
}
const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(Logout);
