/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCountryStates, fetchStateProvinces } from "../../actions";

import { ToastContainer } from "react-toastify";

function LocalityModal({
  onClose,
  isOpen,
  onChooseLocality,
  states,
  provinces,
  fetchCountryStates,
  fetchStateProvinces
}) {
  const [state, setState] = React.useState(null);

  React.useEffect(() => {
    fetchCountryStates(1);
  }, []);

  async function handleStateClick(newState) {
    if (state && state.id === newState.id) return;

    setState(newState);
    fetchStateProvinces(newState.id);
  }

  function renderLocations({ locations, label, handler }) {
    return (
      <>
        {locations.length ? (
          <ul
            className="list-group list-group-flush flex-wrap"
            style={{ maxHeight: "100vh" }}
          >
            {locations.map((location, key) => (
              <li key={key} className="list-group-item border-0">
                <a
                  href="#"
                  className="h6"
                  onClick={e => {
                    e.preventDefault();
                    return handler(location);
                  }}
                >
                  <i className="fa fa-map-marker mr-2"></i>
                  <span className="border-bottom border-dark">
                    {location[label]}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p>Loading...</p>
        )}
      </>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      styles={{ modal: { width: "100%" } }}
    >
      <ToastContainer />
      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-sm-10 mx-auto">
              <h3>{state ? `All ${state.state_label}` : "All Nigeria"}</h3>
              {state && state.id
                ? renderLocations({
                    locations: provinces,
                    label: "province_label",
                    handler: onChooseLocality
                  })
                : renderLocations({
                    locations: states,
                    label: "state_label",
                    handler: handleStateClick
                  })}
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    states: state.metadata.states,
    provinces: state.metadata.provinces
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCountryStates: bindActionCreators(fetchCountryStates, dispatch),
  fetchStateProvinces: bindActionCreators(fetchStateProvinces, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalityModal);
