import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import { ToastContainer } from "react-toastify";
import { withAuthenticated } from "../HOCs/withAuthenticated";

class Store extends Component {
  state = {
    layoutColumns: 3,
    storeId: null
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums
    });
  }

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  componentDidMount() {
    const { slug } = this.props.match.params;
    let parts = slug.split("-");
    const storeId = parts[parts.length - 1];

    this.setState({
      storeId
    });
  }

  render() {
    const { storeId } = this.state;
    return (
      <div>
        <ToastContainer />
        {/*SEO Support*/}
        <Helmet>
          <title>TimeShoppy | Store product</title>
          <meta name="description" content="Timeshoppy - Store Products" />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Collection"} />

        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                {/* <div className="col-sm-3 collection-filter">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <div>
                      <Filter />
                      <NewProduct />
                      <div className="sr-only collection-sidebar-banner">
                        <a href="#">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`}
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </StickyBox> */}
                {/*side-bar banner end here*/}
                {/* </div> */}
                <div className="collection-content col">
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="top-banner-wrapper">
                            <div className="top-banner-content small-section">
                              <h4>Collection</h4>
                            </div>
                          </div>
                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="filter-main-btn">
                                      <span
                                        onClick={this.openFilter}
                                        className="filter-btn btn btn-theme"
                                      >
                                        <i
                                          className="fa fa-filter"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Filter
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <FilterBar
                                      onLayoutViewClicked={colmuns =>
                                        this.LayoutViewClicked(colmuns)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*Products Listing Component*/}
                            {storeId ? (
                              <ProductListing
                                colSize={this.state.layoutColumns}
                                queryParams={{
                                  store_id: storeId
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withAuthenticated(Store, false);
