import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { bindActionCreators } from "redux";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer } from "react-toastify";
import { WalletFundingForm } from "../common/walletFunding";
import Breadcrumb from "../common/breadcrumb";
import Loader from "../common/loader";

import {
  removeFromWishlist,
  fetchCountries as fetchCountriesAction,
  fetchCountryStates as fetchStatesAction,
  fetchStateProvinces as fetchStatesProvincesAction,
  fundWallet as fundWalletAction,
  placeOrder as placeOrderAction,
  clearCart
} from "../../actions";
import { getCartTotal } from "../../services";
import { withAuthenticated } from "../HOCs/withAuthenticated";

var merge = require("lodash.merge");

const CheckOut = props => {
  let addressInput = null;

  const [state, setState] = useState({
    payment: "paystack",
    create_account: false,
    isWalletFundingModalOpen: false,
    isPaymentProcessing: false,
    isSubmitting: false,
    hasSetFieldValues: false,
    orderDetails: {}
  });
  const [address, setAddress] = useState("");
  const [stateId, setStateId] = React.useState(0);
  const [countryId, setCountryId] = React.useState(1);

  const validator = new SimpleReactValidator();

  const saveStateToLocalStorage = () => {
    // save to localStorage
    localStorage.setItem("orderDetails", JSON.stringify(state.orderDetails));
  };

  const hydrateStateWithLocalStorage = () => {
    let orderDetails = JSON.parse(localStorage.getItem("orderDetails"));
    if (!orderDetails) return;

    setState({
      ...state,
      orderDetails: merge({}, state.orderDetails, orderDetails)
    });
  };

  const setDefaultFieldValues = () => {
    if (props.userData) {
      const { country_id, province_id, state_id } = props.userData;

      setState({
        ...state,
        orderDetails: {
          country: country_id,
          city: province_id,
          state: state_id
        }
      });
    }
  };

  useEffect(() => {
    // props.fetchCountries();

    if (countryId) {
      props.fetchStates(countryId);
      props.fetchStateProvinces(0);
      setStateId(0);
      setState({
        ...state,
        orderDetails: {
          ...state.orderDetails,
          state: 0,
          city: 0
        }
      });
    }
  }, [state.orderDetails.country]);

  useEffect(() => {
    props.fetchCountries();

    if (stateId) {
      props.fetchStateProvinces(stateId);
    }
  }, [state.orderDetails.state]);

  useEffect(() => {
    const { userData } = props;

    if (userData && !state.hasFetchedUserData) {
      setState({
        ...state,
        orderDetails: {
          ...state.orderDetails,
          country: userData.country_id,
          city: userData.province_id,
          state: userData.state_id
        },
        hasFetchedUserData: true
      });
      props.fetchStates(userData.country_id);
      props.fetchStateProvinces(userData.state_id);
    }
  }, [props.userData]);

  useEffect(() => {
    // addressInput.focus();
  }, []);

  useEffect(() => {
    const {
      countries,
      cartItems,
      fetchCountries,
      fetchStates,
      fetchStateProvinces,
      location,
      placeOrder
    } = props;
    const {
      orderDetails: { address }
    } = state;

    props.fetchCountries();

    if (!countries.length) props.fetchCountries();

    // fetchStates(1);

    setDefaultFieldValues();

    hydrateStateWithLocalStorage();
    window.removeEventListener("beforeunload", () => saveStateToLocalStorage());

    //
    return () => {
      saveStateToLocalStorage();
    };
  }, []);

  const setStateFromInput = event => {
    setState({
      ...state,
      orderDetails: {
        ...state.orderDetails,
        [event.target.name]: event.target.value
      }
    });
  };

  const onStateChange = e => {
    const stateId = parseInt(e.target.value);
    if (stateId === state.orderDetails.state) return;
    setStateFromInput(e);
    props.fetchStateProvinces(stateId);
  };

  const checkhandle = value => {
    setState({
      ...state,
      payment: value
    });
  };

  const toggleStateProperty = property => {
    setState(state => {
      return {
        ...state,
        [property]: !state[property]
      };
    });
  };

  const toggleFundingWalletModal = () => {
    toggleStateProperty("isWalletFundingModalOpen");
  };

  const handleCheckoutSubmit = async e => {
    e.preventDefault();
    setState({ ...state, isSubmitting: true });
    if (validator.allValid()) {
      const {
        cartItems,
        clearCart,
        total,
        userData,
        placeOrder,
        history,
        symbol,
        settings
      } = props;
      const { payment, orderDetails } = state;

      const vatCharge = total * (settings.vat / 100);
      // const serviceCharge = cartItems.length > 10 ? total * (settings.service_charge / 100): 0
      // hardcode service charge for now
      const serviceCharge = 500;

      const cart_items = cartItems.map(cartItem => ({
        ...cartItem,
        product_id: cartItem.id
      }));

      await placeOrder(
        {
          cart_items,
          billing_address: {
            province_id: orderDetails.city,
            country_id: orderDetails.country,
            state_id: orderDetails.state,
            address
          }
        },
        () => {
          setState({ ...state, isSubmitting: false });
          clearCart();
          return history.push({
            pathname: "/order-success",
            state: {
              address,
              symbol,
              orderSubtotal: total,
              orderTotal: total + serviceCharge,
              items: cartItems,
              payment: {
                id: 1
              }
            }
          });
        }
      );
    } else {
      setState({ ...state, isSubmitting: false });
      validator.showMessages();
    }

    setState({ ...state, isSubmitting: false });
  };

  const Render = () => {
    const {
      cartItems,
      symbol,
      total,
      userData,
      fundWallet,
      countries,
      settings,
      placeOrder
    } = props;
    const {
      isSubmitting,
      isPaymentProcessing,
      isWalletFundingModalOpen
    } = state;

    const vatPercentile =
      settings && "vat" in settings ? settings.vat / 100 : 1;
    const servicePercentile =
      settings && "service_charge" in settings
        ? settings.service_charge / 100
        : 1;
    const vatCharge = total * vatPercentile;
    // const serviceCharge = total * servicePercentile
    const serviceCharge = total >= 10 ? 2 : 0;

    let countriesList = (
      <>
        {countries
          // .filter(({ name }) => name === "Nigeria")
          .map((country, key) => (
            <option value={country.id} key={key}>
              {country.name}
            </option>
          ))}
      </>
    );

    let statesList = (
      <>
        <option value={0} key={0}>
          Select State
        </option>
        {props.states.map((state, key) => (
          <option value={state.id} key={key}>
            {state.state_label}
          </option>
        ))}
      </>
    );

    let provincesList = (
      <>
        <option value={0} key={0}>
          Select Province
        </option>
        {props.provinces.map((province, key) => (
          <option value={province.id} key={key}>
            {province.province_label}
          </option>
        ))}
      </>
    );

    return (
      <>
        <ToastContainer />
        <Modal
          open={isPaymentProcessing}
          onClose={() => toggleStateProperty("isPaymentProcessing")}
        >
          <Loader message="Your order is being placed" />
        </Modal>

        <WalletFundingForm
          isOpen={isWalletFundingModalOpen}
          amount={total}
          onClose={() => toggleFundingWalletModal()}
          fundWallet={fundWallet}
        />
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>TimeShoppy | Checkout Page</title>
            <meta name="description" content="Timeshoppy E-commerce" />
          </Helmet>
          {/*SEO Support End */}

          <Breadcrumb title={"Checkout"} />

          <section className="section-b-space" style={{ paddingTop: "200px" }}>
            <div className="container padding-cls">
              <div className="checkout-page">
                <div className="checkout-form">
                  <form onSubmit={handleCheckoutSubmit}>
                    <div className="checkout row">
                      <div className="col-lg-6 col-sm-12 col-xs-12 mb-3">
                        <div className="checkout-title">
                          <h3>Billing Details</h3>
                        </div>
                        <div className="row check-out">
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Address</div>
                            <input
                              id=""
                              type="text"
                              name="address"
                              autoFocus="autoFocus"
                              value={address}
                              onChange={e => setAddress(e.target.value)}
                              placeholder="Street address"
                              ref={node => {
                                addressInput = node;
                              }}
                            />
                            {validator.message(
                              "address",
                              address,
                              "required|min:5|max:120"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Country</div>
                            <select
                              name="country"
                              value={state.orderDetails.country}
                              onChange={e => {
                                setStateFromInput(e);
                                setCountryId(e.target.value);
                              }}
                            >
                              {countriesList}
                            </select>
                            {/* {validator.message('country', state.orderDetails.country, 'required')} */}
                          </div>
                          <div className="form-group col-md-12 col-sm-6 col-xs-12">
                            <div className="field-label">State</div>
                            <select
                              name="state"
                              defaultValue={state.orderDetails.state}
                              value={state.orderDetails.state}
                              onChange={e => {
                                onStateChange(e);
                                setStateId(e.target.value);
                              }}
                            >
                              {[statesList]}
                            </select>
                            {validator.message(
                              "state",
                              state.orderDetails.state,
                              "required"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Province</div>
                            <select
                              name="city"
                              defaultValue={
                                userData ? userData.province_id : null
                              }
                              value={state.orderDetails.city}
                              onChange={setStateFromInput}
                            >
                              {provincesList}
                            </select>
                            {validator.message(
                              "city",
                              state.orderDetails.city,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 col-xs-12 mb-4">
                        <div className="checkout-details">
                          {
                            <div className="order-box">
                              <div className="title-box">
                                <div>
                                  Product <span> Total</span>
                                </div>
                              </div>
                              <ul className="qty">
                                {cartItems.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      {item.product_name} × {item.qty}{" "}
                                      <span>
                                        {symbol} {item.sum}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                              <ul className="sub-total">
                                <li>
                                  Subtotal{" "}
                                  <span className="count">
                                    {symbol}
                                    {total}
                                  </span>
                                </li>
                                {/* <li>{settings && "vat" in settings? settings.vat: 1}% VAT <span className="count">{symbol}{vatCharge.toFixed(2)}</span></li> */}
                                <li>
                                  {" "}
                                  Service charge{" "}
                                  <span className="count">
                                    {symbol}
                                    {serviceCharge.toFixed(2)}
                                  </span>
                                </li>
                              </ul>

                              <ul className="total">
                                <li>
                                  Total{" "}
                                  <span className="count">
                                    {symbol}
                                    {(total + serviceCharge).toFixed(2)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          }

                          <div className="payment-box">
                            {false ? (
                              <div className="upper-box">
                                <div className="payment-options">
                                  <ul>
                                    <li>
                                      <div className="radio-option stripe">
                                        <input
                                          type="radio"
                                          name="payment-group"
                                          id="payment-2"
                                          defaultChecked={true}
                                          onClick={() =>
                                            checkhandle("paystack")
                                          }
                                        />
                                        <label htmlFor="payment-2">
                                          Pay with card
                                        </label>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="radio-option paypal">
                                        <input
                                          type="radio"
                                          name="payment-group"
                                          id="payment-1"
                                          onClick={() => checkhandle("wallet")}
                                        />
                                        <label htmlFor="payment-1">
                                          Pay with Wallet (N
                                          {userData &&
                                            userData.wallet_balance}{" "}
                                          available)
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            <div className="text-right">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn-solid btn"
                              >
                                {isSubmitting
                                  ? "Order is being placed..."
                                  : "Place Order"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  };

  return <Render />;
};

const mapStateToProps = state => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  userData: state.user.profile,
  total: getCartTotal(state.cartList.cart),
  countries: state.metadata.countries,
  provinces: state.metadata.provinces,
  states: state.metadata.states,
  settings: state.metadata.general_settings[0]
});

const mapDispatchToProps = dispatch => ({
  removeFromWishlist,
  clearCart: bindActionCreators(clearCart, dispatch),
  fetchCountries: bindActionCreators(fetchCountriesAction, dispatch),
  fetchStates: bindActionCreators(fetchStatesAction, dispatch),
  fetchStateProvinces: bindActionCreators(fetchStatesProvincesAction, dispatch),
  fundWallet: bindActionCreators(fundWalletAction, dispatch),
  placeOrder: bindActionCreators(placeOrderAction, dispatch)
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(CheckOut),
  true
);
