import React, { Component } from "react";
import { Link } from "react-router-dom";

const CartHeader = ({ item, total, symbol, removeFromCart }) => {
  return (
    <li>
      <div className="media">
        <Link to={`${process.env.PUBLIC_URL}/products/${item.id}`}>
          {item.hasOwnProperty("main_product_images") ? (
            <img
              alt=""
              className="mr-3"
              src={item.main_product_images[0].image_url}
            />
          ) : null}
        </Link>
        <div className="media-body">
          <Link to={`${process.env.PUBLIC_URL}/products/${item.id}`}>
            <h4>{item.product_name}</h4>
          </Link>
          <h4>
            <span>
              {/* {item.qty} x {symbol} {(item.price * item.discount) / 100} */}
              {item.qty} x {symbol} {item.discount_price}
            </span>
          </h4>
        </div>
      </div>
      {/*<span>{cart}</span>*/}
      <div className="close-circle">
        <a href={null} onClick={removeFromCart}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </a>
      </div>
    </li>
  );
};

export default CartHeader;
