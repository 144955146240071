import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";

// Custom Components
import Header from "./common/headers/header-default";

import Footer from "./common/footers/footer-default";

// ThemeSettings
import ThemeSettings from "./common/theme-settings";

class App extends Component {
  render() {
    return (
      <div>
        <Header logoName={"logo.jpeg"} />
        {this.props.children}
        <Footer logoName={"logo.jpeg"} />
      </div>
    );
  }
}

export default withTranslate(App);
