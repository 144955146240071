import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import {
  registerUser,
  fetchCountries,
  fetchCountryStates,
  fetchStateProvinces
} from "../../actions";
import { registerValidatorSchema, formatErrs } from "../../utils/validators";
import useForm from "react-valida-hook";
import { ToastContainer, toast } from "react-toastify";
import StatsIllustration from "../../assets/statistics.svg";
import ShoppingIllustration from "../../assets/shopping.svg";

let qs = require("qs");

const registerState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone_number: "",
  gender: "",
  country_id: 1,
  state_id: 0,
  province_id: 0
};

const CountriesField = ({ countryId, countries = [], onChange, errors }) => {
  let countriesList = countries
    // .filter(({ name }) => name.toLowerCase() === "nigeria")
    .map((country, key) => (
      <option value={country.id} key={key}>
        {country.name}
      </option>
    ));

  return (
    <div className="form-row mb-2">
      <div className="col-md-6">
        <label htmlFor="state_id">Country</label>
        <select
          className="form-control"
          value={countryId}
          onChange={onChange}
          id="country_id"
          name="country_id"
          required
        >
          <option value="0">Choose country</option>
          {countriesList}
        </select>
        {errors.country_id && (
          <p className="text text-danger">{formatErrs(errors.country_id)}</p>
        )}
      </div>
    </div>
  );
};

const StatesField = ({ stateId, states, onChange, errors }) => {
  let statesList = states.map((state, key) => (
    <option value={state.id} key={key}>
      {state.state_label}
    </option>
  ));

  return (
    <div className="form-row mb-2">
      <div className="col-md-6">
        <label htmlFor="state_id">State</label>
        <select
          className="form-control"
          value={stateId}
          onChange={onChange}
          id="state_id"
          name="state_id"
          required
        >
          <option value="0">Choose state</option>
          {statesList}
        </select>
        {errors.state_id && (
          <p className="text text-danger">{formatErrs(errors.state_id)}</p>
        )}
      </div>
    </div>
  );
};

const ProvincesField = ({
  provinces,
  provinceId,
  stateId,
  errors,
  ...props
}) => {
  let provinceList = provinces.map((province, key) => (
    <option key={key} value={province.id}>
      {province.province_label}
    </option>
  ));
  let showField = provinces.length && stateId;

  return (
    <div className={`form-row collapse ${showField && "show mb-2"}`}>
      <div className="col-md-6">
        <label htmlFor="province_id">LGA</label>
        <select
          className="form-control"
          {...props}
          id="province_id"
          name="province_id"
          required
        >
          <option value="0">Choose LGA</option>
          {provinceList}
        </select>
        {errors.province_id && (
          <p className="text text-danger">{formatErrs(errors.province_id)}</p>
        )}
      </div>
    </div>
  );
};

function Register({
  fetchCountries,
  fetchCountryStates,
  fetchStateProvinces,
  registerUser,
  history,
  location,
  ...props
}) {
  let [formData, validation, validateForm, getData] = useForm(
    {
      ...registerState,
      reg_type_id: 2
    },
    registerValidatorSchema
  );

  const [stateId, setStateId] = React.useState(0);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [countryId, setCountryId] = React.useState(1);

  React.useEffect(() => {
    fetchCountries();

    if (countryId) {
      fetchCountryStates(countryId);
    }
  }, [countryId]);

  React.useEffect(() => {
    fetchCountries();

    if (stateId) {
      fetchStateProvinces(stateId);
    }
  }, [stateId]);


  async function handleRegister(e) {
    e.preventDefault();
    setSubmitting(true);
    const valid = validateForm();

    if (!valid) return setSubmitting(false);

    await registerUser(getData(), function(response) {
      setSubmitting(false);
      return history.push("/success");
    });

    setSubmitting(false);
  }

  return (
    <div>
      <ToastContainer />
      <Breadcrumb title={"create account"} />
      <section className="register-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>create account</h3>
              <div className="theme-card">
                <div className="theme-form">
                  <form
                    className="theme-form"
                    method="post"
                    onSubmit={handleRegister}
                    noValidate
                  >
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="first_name"
                          name="first_name"
                          {...formData.first_name.input}
                        />
                        {validation.errors.first_name && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.first_name)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="review">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="last_name"
                          name="last_name"
                          {...formData.last_name.input}
                        />
                        {validation.errors.last_name && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.last_name)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          {...formData.email.input}
                          required=""
                        />
                        {validation.errors.email && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.email)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="phone_number">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone_number"
                          name="phone_number"
                          required=""
                          {...formData.phone_number.input}
                        />
                        {validation.errors.phone_number && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.phone_number)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="review">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          {...formData.password.input}
                          id="password"
                          name="password"
                          required
                        />
                        {validation.errors.password && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.password)}
                          </p>
                        )}
                      </div>
                    </div>

                    <CountriesField
                      countries={props.countries}
                      countryId={countryId}
                      errors={validation.errors}
                      onChange={e => {
                        const { value } = e.target;
                        setCountryId(value);
                        setStateId(0);
                        formData.country_id.input.onChange(e);
                      }}
                    />
                    <StatesField
                      states={props.states}
                      stateId={stateId}
                      errors={validation.errors}
                      onChange={e => {
                        const { value } = e.target;
                        setStateId(value);
                        fetchStateProvinces(value);
                        formData.state_id.input.onChange(e);
                      }}
                    />
                    <ProvincesField
                      {...formData.province_id.input}
                      provinces={props.provinces}
                      stateId={stateId}
                      errors={validation.errors}
                    />

                    <div className="form-row mt-3">
                      <div className="col-md-12">
                        <label htmlFor="gender">Gender</label>
                        <div className="d-flex">
                          <div className="custom-control custom-radio mr-3">
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              {...formData.gender.input}
                              value="male"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="male"
                            >
                              Male
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              className="custom-control-input"
                              {...formData.gender.input}
                              value="female"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="female"
                            >
                              Female
                            </label>
                          </div>
                        </div>
                        {validation.errors.gender && (
                          <p className="text text-danger">
                            {formatErrs(validation.errors.gender)}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-row mt-2">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-solid"
                          disabled={isSubmitting}
                        >
                          {isSubmitting
                            ? "Creating account.."
                            : "create Account"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    countries: state.metadata.countries,
    states: state.metadata.states,
    provinces: state.metadata.provinces
  };
};

const mapDispatchToProps = dispatch => ({
  registerUser: bindActionCreators(registerUser, dispatch),
  fetchCountryStates: bindActionCreators(fetchCountryStates, dispatch),
  fetchCountries: bindActionCreators(fetchCountries, dispatch),
  fetchStateProvinces: bindActionCreators(fetchStateProvinces, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
