import React, { useState } from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import { withdrawalValidatorSchema } from "../../../utils/validators";

const UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE";

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PRODUCT_STATE:
      return {
        ...state,
        ...payload
      };

    default: {
      throw new Error("Invalid action type");
    }
  }
}

export default function WithdrawalModal({
  balance,
  isOpen,
  onSubmit,
  onClose
}) {
  const [goToAuthWidrawal, setGoToAuthWidrawal] = useState(false);
  const [isBalanceSufficient, setIsBalanceSufficient] = useState(true);

  const formState = {
    amount: 0,
    password: ""
  };

  const initialFormState = {
    ...formState
  };

  let [formData, validation, validateForm, getData] = useForm(
    formState,
    withdrawalValidatorSchema
  );

  let [state, dispatch] = React.useReducer(reducer, initialFormState);

  const checkBalanceSufficiency = () => {
    const sufficiency = Number(getData().amount) < balance;
    setIsBalanceSufficient(sufficiency);
    return sufficiency;
  };

  const handleSetGoToAuthWidrawal = value => {
    if (!checkBalanceSufficiency()) return;
    setGoToAuthWidrawal(value);
  };

  let handleSubmit = e => {
    e.preventDefault();
    console.log(getData());
    if (!validateForm()) return;

    let data = {
      ...getData()
    };

    return onSubmit(data);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <form className="section-b-space px-3 py-4" onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <h3 className="w-100 col-12  py-3">Withdraw Funds</h3>
              {!goToAuthWidrawal && (
                <div className="col-12 py-3">
                  <p>
                    Withdrawals are instant. Kindly contact support if you{" "}
                    <br /> are not credited after 24 hours.
                  </p>
                  <label>Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    name="amount"
                    {...formData.amount.input}
                    min={0}
                    style={{ width: "100%", maxWidth: "100%" }}
                  />

                  {!isBalanceSufficient && (
                    <p className="text-danger">Insufficient fund</p>
                  )}

                  <div
                    style={{ width: "fit-content", marginLeft: "auto" }}
                    className="offset-xl-3 offset-sm-4"
                  >
                    <button
                      className="btn btn-warning"
                      onClick={() => handleSetGoToAuthWidrawal(true)}
                    >
                      Next
                    </button>
                    <button
                      type="button"
                      onClick={onClose}
                      className="btn btn-light"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}

              {goToAuthWidrawal && (
                <div className="col-12 py-3">
                  <h5>Amount: ₦{getData().amount}</h5>
                  <p>Please kindly authorize this action.</p>
                  <input
                    className="form-control"
                    id="password"
                    name="password"
                    {...formData.password.input}
                    type={"password"}
                    placeholder="Your password"
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      className="btn"
                      onClick={() => setGoToAuthWidrawal(false)}
                    >
                      Back
                    </button>
                    <div className="offset-xl-3 offset-sm-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Withdraw
                      </button>
                      <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-light"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
