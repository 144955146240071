import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Loader from "../../common/loader";
import "../../common/index.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { withAuthenticated } from "../../HOCs/withAuthenticated";
import { ToastContainer } from "react-toastify";
// Import custom components
import TopCollection from "./top-collection";
import SpecialProducts from "../common/products";
import { fetchProductCategories, getAllProducts } from "../../../actions";
import {
  svgFreeShipping,
  svgservice,
  svgoffer
} from "../../../services/script";
import { getCategoryIcon } from "../../../utils";

const categoryBanners = [
  {
    slug: 1,
    title: "Computer",
    tagLine: "Top Rated",
    bannerSrc: "computer-banner.jpg"
  },
  {
    slug: 3,
    title: "Phones",
    tagLine: "New Arrivals",
    bannerSrc: "phone-banner.jpg"
  },
  {
    slug: 4,
    title: "Fashion",
    tagLine: "Sleek and Classy",
    bannerSrc: "fashion-banner.jpg"
  },
  {
    slug: 5,
    title: "Kitchen",
    tagLine: "Cozy and Homely",
    bannerSrc: "kitchen-banner.jpg"
  },
  {
    slug: 6,
    title: "Electronics",
    tagLine: "Just got in",
    bannerSrc: "electronics-banner.jpg"
  },
  {
    slug: 6,
    title: "Automobiles",
    tagLine: "Fast & Lovely",
    bannerSrc: "automobile-banner.jpg"
  }
];

function renderCategoryBanner(
  { title, tagLine, slug, bannerSrc, cols = 6 },
  key
) {
  return (
    <div className={`col-md-${cols} mb-4`} key={key}>
      <Link to={`${process.env.PUBLIC_URL}/category/${slug}`}>
        <div className="collection-banner p-right text-center">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/${bannerSrc}`}
            className="img-fluid"
            alt=""
          />
          <div className="contain-banner">
            <div
              className="p-4"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.75)"
              }}
            >
              <h4>{tagLine}</h4>
              <h2>{title}</h2>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

let sliderBanners = [
  {
    slug: 6,
    title: "Men's Fashion",
    tagLine: "Hot",
    bannerIdx: 1
  },
  {
    slug: 7,
    title: "Belle's Fashion",
    tagLine: "Sultry",
    bannerIdx: 2
  }
];

function renderSliderBanner({ title, tagLine, slug, bannerIdx }, key) {
  return (
    <div key={key}>
      <div className={`home home${bannerIdx} text-center`}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="slider-contain">
                <div>
                  <h4 className="text-light">{tagLine}</h4>
                  <h1 className="text-light">{title}</h1>
                  <Link
                    to={`${process.env.PUBLIC_URL}/category/${slug}`}
                    className="btn btn-solid"
                  >
                    shop now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class Main extends Component {
  state = {
    isLoading: false
  };

  async componentDidMount() {
    const { fetchProductCategories, fetchProducts } = this.props;
    document.getElementById("color").setAttribute("href", `#`);
    document.querySelector(".loader-wrapper").style = "display: false";
    this.setState({ isLoading: true });
    await fetchProductCategories();
    await fetchProducts();
  }

  renderCategoriesCard() {
    const { categories } = this.props;
    const isLoading = !categories.length;

    return (
      <div className="card py-4">
        {isLoading && <Loader label="Loading..." />}
        {!isLoading ? (
          <ul className="nav flex-column">
            {categories.map(({ id, category_label }) => {
              return (
                <li className="nav-item" style={{ borderWidth: 0 }} key={id}>
                  <a href={`/category/${id}`} className="nav-link text-dark h6">
                    <i
                      style={{ width: "45px" }}
                      className={`fa fa-${getCategoryIcon(
                        id
                      )} fa-1x mr-2 py-1 d-inline-block`}
                    ></i>
                    {category_label}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }

  render() {
    const { products, user } = this.props;
    return (
      <div>
        <ToastContainer />
        <Helmet>
          <title>Timeshoppy | Find bargain prices</title>
          <meta name="description" content="Timeshoppy E-commerce" />
        </Helmet>
        <div className="container">
          <div className="row">
            {user &&
              user.profile &&
              ["Shop Admin", "Super Admin"].includes(
                user.profile.role_label
              ) && (
                <div className="p-0 col-md-12 sr-only">
                  <div
                    className="alert alert-warning seller-alert"
                    role="alert"
                  >
                    As a {user.profile.role_label}, you're ineligible to make
                    purchases. &nbsp;
                    <a
                      href="https://admin.timeshoppy.com"
                      className="alert-link"
                    >
                      Click here to visit the {user.profile.role_label} area
                    </a>
                  </div>
                </div>
              )}

            {/*Home Slider*/}
            <section className="p-0 col-md-8">
              <Slider className="slide-1 home-slider">
                {sliderBanners.map(renderSliderBanner)}
              </Slider>
            </section>
            {/*Home Section End*/}
            <div className="col-md-4">{this.renderCategoriesCard()}</div>
          </div>
          {products.length ? <SpecialProducts /> : null}

          {/*collection banner*/}
          <section className="pb-0">
            <div className="container">
              <div className="row partition2">
                {categoryBanners.map(renderCategoryBanner)}
              </div>
            </div>
          </section>
          {/*collection banner end*/}

          {/*service layout*/}
          <div className="container">
            <section className="service border-section small-section ">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div
                      dangerouslySetInnerHTML={{ __html: svgFreeShipping }}
                    />
                    <div className="media-body">
                      <h4>Free shipping</h4>
                      <p>free shipping within specific localities</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                    <div className="media-body">
                      <h4>24 X 7 service</h4>
                      <p>Dependable service for you</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                    <div className="media-body">
                      <h4>Great offers</h4>
                      <p>We have great offers on products</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.metadata.categories,
    products: state.data.products,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProductCategories: bindActionCreators(fetchProductCategories, dispatch),
  fetchProducts: bindActionCreators(getAllProducts, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthenticated(Main, false));
