import React, { useState } from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import { withdrawalValidatorSchema } from "../../../utils/validators";

const UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE";

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PRODUCT_STATE:
      return {
        ...state,
        ...payload
      };

    default: {
      throw new Error("Invalid action type");
    }
  }
}

export default function WithdrawalModal({isOpen, onSubmit, onClose }) {
  const formState = {
    password: '',
  };

  let [formData, validation, validateForm, getData] = useForm(
    formState,
    withdrawalValidatorSchema
  );

  let handleSubmit = e => {
    e.preventDefault();
    // if (!validateForm()) return;

    let data = {
      ...getData(),
    }; 

    return onSubmit(data);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <form className="section-b-space px-3 py-4" onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <h5 className="w-100 col-12  py-3">
                Authorization required
              </h5>              
              <div className="col-12 py-3">
                <input
                  className="form-control"
                  id="password"
                  name="password"
                  {...formData.password.input}
                  type={"password"}
                  placeholder="Your password"
                />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                      <button type="submit" className="btn btn-primary">
                        Proceed
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
