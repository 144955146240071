export function getCategoryIcon(id) {
  let icons = {
    "1": "desktop",
    "2": "volume-control-phone",
    "3": "plug",
    "4": "venus-double",
    "5": "cutlery",
    "6": "child",
    "7": "car",
    "8": "glass"
  };

  return icons[id];
}
