import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";

class TopBar extends Component {
  render() {
    const { translate, isSignedIn, user } = this.props;
    const isSuperAdmin =
      user.profile && user.profile.role_label === "Super Admin";
    const isCustomer = user.profile && user.profile.role_label === "Customer";
    const isShopAdmin =
      user.profile && user.profile.role_label === "Shop Admin";

    return (
      <div className="top-header bg-primary text-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="header-contact">
                <ul>
                  {/* <li>
                    {translate("topbar_title", { theme_name: "Timeshoppy" })}
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="header-dropdown">
                <li className="">
                  <a
                    href={`${process.env.PUBLIC_URL}/faq`}
                    className="text-light"
                  >
                    <i
                      className="fa fa-question text-light"
                      aria-hidden="true"
                    ></i>
                    FAQ
                  </a>
                  {/* <i className="fa fa-phone" aria-hidden="true"></i> */}
                </li>

                {!isSignedIn ? (
                  <>
                    <li className="mobile-wishlist compare-mobile">
                      <Link
                        to={`${process.env.PUBLIC_URL}/login`}
                        className="text-light"
                      >
                        <i
                          className="fa fa-key text-light"
                          aria-hidden="true"
                        ></i>
                        Log in/Sign in
                      </Link>
                    </li>
                    <li className="mobile-account">
                      <Link
                        to={`${process.env.PUBLIC_URL}/register`}
                        className="text-light"
                      >
                        <i
                          className="fa fa-user text-light"
                          aria-hidden="true"
                        ></i>
                        SELL
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    {isCustomer && (
                      <li className="mobile-wishlist compare-mobile">
                        <a
                          href={`${process.env.PUBLIC_URL}/dashboard`}
                          className="text-light"
                        >
                          <i
                            className="fa fa-dashboard text-light"
                            aria-hidden="true"
                          />
                          Dashboard
                        </a>
                      </li>
                    )}
                    {isShopAdmin && (
                      <li className="mobile-wishlist compare-mobile">
                        <a
                          href={process.env.ADMIN_APP_URL}
                          className="text-light"
                        >
                          <i
                            className="fa fa-dashboard text-light"
                            aria-hidden="true"
                          />
                          Visit Sellers Area
                        </a>
                      </li>
                    )}
                    {isSuperAdmin && (
                      <li className="mobile-wishlist compare-mobile">
                        <a
                          href={process.env.ADMIN_APP_URL}
                          className="text-success text-light"
                        >
                          <i
                            className="fa fa-dashboard text-light"
                            aria-hidden="true"
                          ></i>
                          <b>Visit Admin Dashboard</b>
                        </a>
                      </li>
                    )}
                    <li className="mobile-account">
                      <a
                        href={`${process.env.PUBLIC_URL}/logout`}
                        className="text-light"
                      >
                        <i
                          className="fa fa-lock text-light"
                          aria-hidden="true"
                        ></i>
                        Logout
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslate(TopBar));
