import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { resetPassword } from "../../actions";
import api from '../../store/api';
import { withAuthenticated } from "../HOCs/withAuthenticated";
import Breadcrumb from "../common/breadcrumb";

const paymentStatusMessages = {
  success: 'Payment successfully verified. Redirecting...',
  failed: 'Payment failed',
  pending: 'Payment is being processed',
}

function PaymentVerification({ match }) {
  const [status, setStatus] = useState(null);
  const [isVerifying, setIsVerifying] = React.useState(false);
  let { reference } = match.params;

  const verifyPayment = async () => {
    if (isVerifying) return;
    setIsVerifying(true);

    try {
      if (reference) {
        const response = await api.verifyPayment(reference);
        const responseBody = await response.json();
        const statusCode = response.status;

        if (statusCode != 200) {
          throw new Error(responseBody.message);
        }

        setStatus(responseBody.result ? responseBody.result.status : status);
      }
    } catch (error) {
      toast.error(error.message);
    }

    setIsVerifying(false);
  };

  useEffect(() => {
    verifyPayment();
  }, []);

  useEffect(() => {
    if (status === 'success') {
      document.location.href = '/';
    }
  }, [status]);

  return (
    <div>
      <ToastContainer />
      {/* <Breadcrumb title={"reset password"} /> */}

      <section className="pwd-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              {isVerifying && <h2>Verifying  payment. Please wait...</h2>}
              {status && <h2>{paymentStatusMessages[status]}</h2>}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    symbol: state.data.symbol,
    countries: state.metadata.countries,
    states: state.metadata.states,
    provinces: state.metadata.provinces,
  };
};

const mapDispatchToProps = dispatch => ({
  resetPassword: bindActionCreators(resetPassword, dispatch)
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(PaymentVerification)
);
