import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import useForm from "react-valida-hook";
import { ToastContainer, toast } from "react-toastify";
import { resetPassword } from "../../actions";
import {
  resetPasswordValidatorSchema,
  formatErrs
} from "../../utils/validators";
import Breadcrumb from "../common/breadcrumb";

const initialState = {
  password: ""
};

function ResetPassword({ history, match, resetPassword }) {
  const [isSubmitDisabled, setSubmitDisabled] = React.useState(false);
  let [formData, validation, validateForm, getData] = useForm(
    initialState,
    resetPasswordValidatorSchema
  );
  let { token } = match.params;

  const handleResetPassword = async e => {
    e.preventDefault();
    setSubmitDisabled(true);
    let valid = validateForm();

    if (!valid) return setSubmitDisabled(false);
    let payload = {
      token,
      ...getData()
    };
    await resetPassword(payload, () => {
      toast.success("Your password has been reset successfully");
      setTimeout(function() {
        setSubmitDisabled(false);
        history.push("/password-reset-success");
      }, 3000);
    });

    setSubmitDisabled(false);
  };

  return (
    <div>
      <ToastContainer />
      <Breadcrumb title={"reset password"} />

      {/*Forget Password section*/}
      <section className="pwd-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h2>Reset Your Password</h2>
              <form className="theme-form" onSubmit={handleResetPassword}>
                <div className="form-row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      placeholder="Enter a new password"
                      required=""
                      {...formData.password.input}
                    />
                    {validation.errors.password && (
                      <p className="text text-danger">
                        {formatErrs(validation.errors.password)}
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitDisabled}
                    className="btn btn-solid"
                  >
                    {isSubmitDisabled ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  resetPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
