// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const SET_SEARCH_PRODUCTS = "SET_SEARCH_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

// Register & Login User
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";

export const SET_PROFILE = "SET_PROFILE";
export const PLACE_ACTION = "PLACE_ACTION";

// Country, States & Provinces
export const FETCH_COUNTRY_STATES = "FETCH_COUNTRY_STATES";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_STATE_PROVINCES = "FETCH_STATE_PROVINCES";

// Banks and Bank Detail
export const FETCH_BANKS = "FETCH_BANKS";
export const FETCH_BANK_DETAIL = "FETCH_BANK_DETAIL";

// Wallets and Wallet Transactions
export const FETCH_WALLET_TRANSACTIONS = "FETCH_WALLET_TRANSACTIONS";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";

export const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
export const FETCH_SETTINGS = "FETCH_SETTINGS";

export const SET_ORDERS = "SET_ORDERS";
export const FETCH_ORDER = "FETCH_ORDER";

export const FETCH_ORDER_NOTIFICATIONS = "FETCH_ORDER_NOTIFICATIONS";
