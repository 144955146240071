import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginUser } from "../../actions";
import { loginValidatorSchema, formatErrs } from "../../utils/validators";
import useForm from "react-valida-hook";
import { ToastContainer, toast } from "react-toastify";

import Breadcrumb from "../common/breadcrumb";
import { withAuthenticated } from "../HOCs/withAuthenticated";

const loginState = {
  email: "",
  password: ""
};

function Login({ loginUser, user, history }) {
  const [isSubmitDisabled, setSubmitDisabled] = React.useState(false);
  React.useEffect(() => {
    if (user) {
      history.push("/dashboard");
    }
  });
  let [formData, validation, validateForm, getData] = useForm(
    loginState,
    loginValidatorSchema
  );

  const { PUBLIC_URL } = process.env;

  const handleLogin = async e => {
    e.preventDefault();
    setSubmitDisabled(!isSubmitDisabled);
    if (!validateForm()) return setSubmitDisabled(false);

    await loginUser(getData(), () => {
      setSubmitDisabled(false);
      toast.success("Successfully logged in");
      return history.push("/dashboard");
    });

    setSubmitDisabled(false);
  };

  return (
    <div>
      <ToastContainer />
      <Breadcrumb title={"Login"} />

      {/*Login section*/}
      <section className="login-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>Login</h3>
              <div className="theme-card">
                <form onSubmit={handleLogin} className="theme-form">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required=""
                      {...formData.email.input}
                    />
                    {validation.errors.email && (
                      <p className="text text-danger">
                        {formatErrs(validation.errors.email)}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="">
                      <label htmlFor="review">Password</label>
                      <a
                        href="/forgot-password"
                        tabIndex="0"
                        className="pull-right"
                      >
                        Forgot password?
                      </a>
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      id="review"
                      placeholder="Enter your password"
                      required=""
                      {...formData.password.input}
                    />
                    {validation.errors.email && (
                      <p className="text text-danger">
                        {formatErrs(validation.errors.password)}
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    tabIndex="1"
                    disabled={isSubmitDisabled}
                    className="btn btn-solid"
                  >
                    {isSubmitDisabled ? "Signing in..." : "Login"}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 right-login">
              <h3>New Customer</h3>
              <div className="theme-card authentication-right">
                <h6 className="title-font">Create Account</h6>
                <p>
                  Sign up for a free account. Registration is quick and easy.
                </p>
                <a href={`${PUBLIC_URL}/register`} className="btn btn-solid">
                  Create an Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    user: state.user.profile
  };
};

const mapDispatchToProps = dispatch => ({
  loginUser: bindActionCreators(loginUser, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthenticated(Login, false));
