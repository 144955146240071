import React, { Fragment } from "react";

export default props => {
  return (
    <Fragment>
      <div className="account-sidebar">
        <a className="popup-btn">my account</a>
      </div>
      <div className="card py-4 mb-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <a
              href={`${process.env.PUBLIC_URL}/dashboard`}
              className="nav-link"
            >
              Dashboard
            </a>
          </li>
          <li className="nav-item">
            <a
              href={`${process.env.PUBLIC_URL}/dashboard/orders`}
              className="nav-link"
            >
              My Orders
            </a>
          </li>
          <li className="nav-item">
            <a
              href={`${process.env.PUBLIC_URL}/dashboard/wallet`}
              className="nav-link"
            >
              Wallet
            </a>
          </li>
          <li className="nav-item">
            <a href={`${process.env.ADMIN_APP_URL}`} className="nav-link">
              Seller Area
            </a>
          </li>
          <li className="nav-item">
            <a href={`${process.env.PUBLIC_URL}/logout`} className="nav-link">
              Log out
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
