/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";

// Import custom components
import store from "../../../store";
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "../../../containers/CartContainer";
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";
import { changeCurrency } from "../../../actions";
import { connect } from "react-redux";
import LocalityModal from "../localityModal";

const qs = require("querystring");

class Header extends Component {
  constructor(props) {
    super(props);

    // this.searchInput = React.createRef()
    this.state = {
      isLocalityModalOpen: false,
      isSearchModalOpen: false,
      locality: null,
      isLoading: false,
      search: ""
    };
  }
  /*=====================
         Pre loader
        ==========================*/
  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);

    this.setState({ open: true });
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 300) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky").classList.remove("fixed");
      } else document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  openSearch = () => {
    this.setState({ isSearchModalOpen: true });
    setTimeout(() => {
      this.searchInput.focus();
    }, 0);
  };

  openLocalityModal = e => {
    e.preventDefault();
    this.setState({ isLocalityModalOpen: true });
  };

  closeSearch = () => {
    this.setState({ isSearchModalOpen: false });
  };

  handleSearchSubmit = e => {
    e.preventDefault();

    const { search, locality } = this.state;
    const queryParams = locality
      ? {
          q: search,
          province: locality.province_label,
          pid: locality.id
        }
      : {
          q: search
        };

    this.props.history.push(`/search?${qs.stringify(queryParams)}`);
    this.closeSearch();
  };

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  render() {
    const {
      user,
      categories,
      location: { pathname }
    } = this.props;
    const { locality, isSearchModalOpen, isLocalityModalOpen } = this.state;
    const isHomePage = pathname === "/";

    return (
      <div>
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option"></div>
          {/*Top Header Component*/}
          <TopBar isSignedIn={!!user.profile} />

          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left" style={{ flex: 1 }}>
                    <div className="navbar">
                      <button onClick={this.openNav}>
                        <div className="bar-style">
                          {" "}
                          <i
                            className="fa fa-bars sidebar-bar"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </button>
                      {/*SideBar Navigation Component*/}
                      <SideBar categories={categories} />
                    </div>
                    <div className="brand-logo col-6 col-md-3">
                      <LogoImage logo={this.props.logoName} />
                      {!isHomePage && (
                        <a
                          href={process.env.PUBLIC_URL}
                          className="btn btn-link"
                        >
                          Go home
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="menu-right align-items-center">
                    <div className="">
                      <div className="input-group" onClick={this.openSearch}>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                              onClick={this.openSearch}
                              className="img-fluid"
                              alt=""
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          disabled
                          className="form-control d-none d-md-block"
                          placeholder="Search Products"
                          required
                        />
                      </div>
                    </div>

                    {/*Top Navigation Bar Component*/}
                    {/* <NavBar/> */}

                    <div>
                      <div className="icon-nav">
                        <ul className="d-flex">
                          <li className="onhover-div mobile-search">
                            <i
                              className="fa fa-search"
                              onClick={this.openSearch}
                            ></i>
                          </li>

                          {/* { !user.profile ?
													<li className="onhover-div mobile-setting">
														<div>
															<img src={`${process.env.PUBLIC_URL}/assets/images/icon/users.png`} className="img-fluid" alt="" />
															<b className="hidden-md"><span className="fa fa-caret-down"></span></b>
														</div>
														<div className="show-div setting">
															<ul>
																<li><a href={`${process.env.PUBLIC_URL}/login`} className="btn btn-solid btn-block">Login</a> </li>
																<li className="d-block text-center py-2">or</li>
																<li><a href={`${process.env.PUBLIC_URL}/register`} className="btn btn-link btn-block">Register</a> </li>
															</ul>
														</div>
													</li>:
													<li className="onhover-div mobile-setting">
														<a href={`${process.env.PUBLIC_URL}/dashboard`}>
															<img src={`${process.env.PUBLIC_URL}/assets/images/icon/users.png`} className="img-fluid" alt="" />
															<b className="hidden-md text-dark">Dashboard</b>
														</a>
													</li>
												} */}
                          {/*Header Cart Component */}
                          <CartContainer />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          id="search-overlay"
          className="search-overlay"
          style={{
            display: isSearchModalOpen && "block"
          }}
        >
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <span>Find products in </span>
                      <a
                        href="#"
                        onClick={this.openLocalityModal}
                        className="ml-1 btn btn-sm btn-dark"
                      >
                        <i className="fa fa-map-marker" />
                        {locality ? locality.province_label : "All Nigeria"}
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <form onSubmit={this.handleSearchSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="search"
                          id="searchInput"
                          value={this.state.search}
                          autoFocus
                          ref={ref => (this.searchInput = ref)}
                          onChange={e => {
                            e.preventDefault();
                            const { name, value } = e.target;

                            this.setState({ [name]: value });
                          }}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LocalityModal
            isOpen={isLocalityModalOpen}
            onChooseLocality={locality => {
              this.setState({ locality, isLocalityModalOpen: false });
            }}
            onClose={() => this.setState({ isLocalityModalOpen: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    categories: state.metadata.categories
  };
};

const mapDispatchToProps = dispatch => ({
  changeCurrency
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
