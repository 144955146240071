import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SearchResults from "../../components/collection/common/search-results";
import Breadcrumb from "../common/breadcrumb";
import { searchProducts } from "../../actions";
const qs = require("qs");

class Search extends Component {
  state = {
    query: "",
    layoutColumns: 12
  };

  componentDidMount() {
    const { location, makeSearch } = this.props;
    const queryParams = qs.parse(location.search.split("?")[1]);

    if (Object.keys(queryParams).length) {
      const { q, pid, province } = queryParams;
      this.setState({ query: q, province, pid });
      makeSearch({ q, province: pid });
    }
  }

  onSearchSubmit(e) {
    e.preventDefault();
    const { query, q, pid } = this.state;
    const { history, makeSearch } = this.props;
    const optionalParams = {
      province: pid
    };

    makeSearch({ q: query });
    history.push(`/search?${qs.stringify({ query, ...optionalParams })}`);
  }

  onInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div>
        <Breadcrumb title={"Search"} />

        {/*Search section*/}
        <section className="authentication-page section-b-space">
          <div className="container">
            <section className="search-block mb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <form
                      className="form-header mb-5"
                      onSubmit={e => this.onSearchSubmit(e)}
                    >
                      <div className="input-group">
                        <input
                          name="query"
                          type="text"
                          className="form-control"
                          aria-label="Amount (to the nearest dollar)"
                          placeholder="Search Products......"
                          onChange={e => this.onInputChange(e)}
                          defaultValue={this.state.query}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-solid">
                            <i className="fa fa-search"></i>Search
                          </button>
                        </div>
                      </div>
                    </form>
                    {/*Products Listing Component*/}
                    <SearchResults colSize={this.state.layoutColumns} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    products: state.data.products
  };
};

const mapDispatchToProps = dispatch => ({
  makeSearch: bindActionCreators(searchProducts, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
