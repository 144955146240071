import React, { Component } from "react";
import { Link } from "react-router-dom";

import LogoImage from "../headers/common/logo";
import { SlideUpDown } from "../../../services/script";

class Footer extends Component {
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  render() {
    return (
      <footer className="footer-light pet-layout-footer">
        <div className="light-layout">
          <div className="container">
            <section className="small-section">
              <div className="row footer-theme2">
                <div className="col">
                  <div className="footer-link link-white">
                    <div className="footer-brand-logo">
                      <LogoImage
                        logo={this.props.logoName}
                        style={{ width: "20%" }}
                      />
                    </div>
                    <div className="footer-link">
                      <ul>
                        <li className="footer-link">
                          <Link to={`${process.env.PUBLIC_URL}/faq`}>
                            FAQ (Frequently asked questions)
                          </Link>
                        </li>
                        <li className="footer-link">
                          <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`}>
                            Terms and Conditions
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="social-white sr-only">
                      <ul>
                        <li>
                          <Link to={"https://www.facebook.com/"}>
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          <Link to={"https://twitter.com"}>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to={"https://instagram.com"}>
                            <i
                              className="fa fa-instagram"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="footer-title footer-mobile-title">
                                                <h4>my account</h4>
                                            </div>
                                            <div className="footer-contant">
                                                <ul>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >womens</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >clothing</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >accessories</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >featured</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >service</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >cart</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >my order</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >FAQ</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >new product</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >featured product</Link></li>
                                                </ul>
                                            </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="sub-footer dark-subfooter bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="footer-end">
                  <p>
                    <span className="mr-3">
                      <i className="fa fa-copyright" aria-hidden="true"></i>{" "}
                      {new Date().getFullYear()} {process.env.APP_NAME}
                    </span>
                    <span className="mr-3">
                      <Link
                        to={`${process.env.PUBLIC_URL}/faq`}
                        className="text-white"
                      >
                        FAQ
                      </Link>
                    </span>
                    
                    <Link
                      to={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                      className="text-white"
                    >
                      Terms and Conditions
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="payment-card-bottom">
                  <ul>
                    <li>
                      <span>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`}
                          alt=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`}
                          alt=""
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
