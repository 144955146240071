import React, { Component } from "react";
import Slider from "react-slick";

class SmallImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav2: null
    };
  }
  componentDidMount() {
    this.setState({
      nav2: this.slider2
    });
  }

  render() {
    const { item, settings } = this.props;

    var productsnav = settings;

    return (
      <div className="row">
        <div className="col-12 p-0">
          <Slider
            {...productsnav}
            asNavFor={this.props.navOne}
            ref={slider => (this.slider2 = slider)}
            className="slider-nav"
          >
            {item.main_product_images.length > 0 &&
              item.main_product_images.map((item, index) => (
                <div key={index}>
                  <img
                    src={`${item.image_url}`}
                    key={index}
                    alt={item.product_name}
                    width={"25%"}
                    className="img-fluid img-thumbnail"
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default SmallImages;
