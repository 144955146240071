/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchProductCategories } from "../../../../actions";

class SideBar extends Component {
  componentDidMount() {
    this.props.fetchProductCategories();
  }

  closeNav() {
    var closemyslide = document.getElementById("mySidenav");
    if (closemyslide) closemyslide.classList.remove("open-side");
  }

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensidesubmenu"))
      event.target.nextElementSibling.classList.remove("opensidesubmenu");
    else {
      event.target.nextElementSibling.classList.add("opensidesubmenu");
    }
  };

  render() {
    const { categories } = this.props;

    return (
      <>
        <div id="mySidenav" className="sidenav">
          <a
            className="sidebar-overlay"
            style={{ left: "300px", height: "100%" }}
            onClick={this.closeNav}
          ></a>

          <nav>
            <a onClick={this.closeNav}>
              <div className="sidebar-back text-left">
                <i className="fa fa-angle-left pr-2" aria-hidden="true"></i>{" "}
                Back
              </div>
            </a>
            {this.props.user.profile ? (
              <Link to={`${process.env.PUBLIC_URL}/logout`}>
                <div className="sidebar-back text-center">
                  <i className="fa fa-unlock pr-2" aria-hidden="true"></i>{" "}
                  Logout
                </div>
              </Link>
            ) : (
              <>
                <Link to={`${process.env.PUBLIC_URL}/login`}>
                  <div className="sidebar-back text-center">
                    <i className="fa fa-key pr-2" aria-hidden="true"></i> Login
                  </div>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/register`}>
                  <div className="sidebar-back text-center">
                    <i className="fa fa-user pr-2" aria-hidden="true"></i>{" "}
                    Register
                  </div>
                </Link>
              </>
            )}
            {categories.length ? (
              <ul id="sub-menu" className="sidebar-menu">
                {categories.map(({ id, category_label, sub_category }, key) => (
                  <li key={key}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleMegaSubmenu(e);
                      }}
                    >
                      {category_label}
                      <span
                        className="sub-arrow"
                        onClick={(e) => this.handleMegaSubmenu(e)}
                      >
                        &nbsp;
                      </span>
                    </Link>
                    <ul className="mega-menu clothing-menu">
                      <li>
                        <div className="row m-0">
                          <div className="col-xl-4">
                            <div className="link-section">
                              {sub_category && sub_category.length ? (
                                <ul>
                                  {sub_category.map(
                                    ({ sub_category_label }, subKey) => (
                                      <li key={subKey}>
                                        <Link to={`/category/${id}`}>
                                          {sub_category_label}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            ) : null}
          </nav>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    categories: state.metadata.categories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProductCategories: bindActionCreators(fetchProductCategories, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
