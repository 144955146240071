/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Slider from 'react-slick'

var slideSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


class ProductItem extends Component {

    constructor(props){
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
            color: '',
            size: ''
        }
    }

    onClickHandle(img) {
        this.setState({ image : img} );
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if(this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        const { stock } = this.props.product
        if(this.state.quantity < stock) {
            this.setState({quantity: this.state.quantity+1})
        }else{
            this.setState({stock: 'Out of Stock !'})
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    componentDidMount() {
        this.setState({
            quantity: this.props.product.stock
        })
    } 

    render() {
        const {product, symbol, onAddToCartClicked, onAddToWishlistClicked, onAddToCompareClicked} = this.props;
        const { quantity, color, size } = this.state

        let RatingStars = []
        for(var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        return (
            <div className="product-box col-sm-4">
                <div className="img-wrapper">
                    <div className="lable-block">
                        {<span className="lable3" style={{ borderRadius: '25px' }}>recent</span>}
                    </div>
                    <div className="front">
                        <a onClick={this.onOpenModal}><img
                            src={`${product.hasOwnProperty("main_product_images") && product.main_product_images.length ? product.main_product_images[0].image_url: null}`
                            }
                            className="img-fluid"
                            alt="" /></a>
                    </div>
                    <div className="cart-info cart-wrap">
                        <a href="javascript:void()" title="Add to cart" onClick={() => onAddToCartClicked({ ...product, color, size}, quantity)}>
                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </a>
                        <a href="javascript:void()" title="Add to Wishlist" onClick={onAddToWishlistClicked}>
                            <i className="fa fa-heart" aria-hidden="true"></i>
                        </a>
                        <a href="javascript:void()" data-toggle="modal"
                            data-target="#quick-view"
                            title="Quick View"
                            onClick={this.onOpenModal}><i className="fa fa-search" aria-hidden="true"></i></a>
                        {/* <Link to={`${process.env.PUBLIC_URL}/compare`} title="Compare" onClick={onAddToCompareClicked}>
                            <i className="fa fa-refresh" aria-hidden="true"></i></Link> */}
                    </div>
                </div>
                <div className="product-detail">
                    <div>
                        <div className="rating">
                            {RatingStars}
                        </div>
                        <Link to={`${process.env.PUBLIC_URL}/products/${product.id}`}>
                            <h6>{product.product_name}</h6>
                        </Link>
                        <h4>{symbol}{product.price-(product.price*product.discount/100)}
                            <del><span className="money">{symbol}{product.price}</span></del>
                        </h4>
                        {product.colors?
                        <ul className="color-variant">
                            {product.colors.map((vari, i) =>
                                <li style={{ 
                                    borderRadius: '100%',
                                    border: vari.color !== this.state.color ? '1px solid #ccc': '5px solid #999',
                                    background: vari.color}} key={i} title={vari.color} onClick={() => this.setState({ color: vari.color })}></li>)
                            }
                        </ul>:''}
                        {product.sizes?
                            <div className="size-box mt-2">
                                <ul>
                                {product.sizes.map(({ size }, i) => {
                                    return (
                                        <li key={i} className="mr-1">
                                            <a href="#" className="d-flex p-2 align-items-center justify-content-center" style={{
                                                height: "30px",
                                                border: size !== this.state.size ? '1px solid #ccc': '2px solid #999'
                                            }} onClick={e => {
                                                e.preventDefault()
                                                this.setState({ size })
                                            }}>{size}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>:''}
                    </div>
                </div>
                <Modal styles={{
                    modal: {
                        maxWidth: '100%'
                    }
                }} open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-6  col-xs-12">
                                        {product.main_product_images.length ?
                                        <div className="quick-view-img">
                                            <Slider {...slideSettings}>
                                            {product.main_product_images.map((photo, i) => 
                                               <React.Fragment key={i}>
                                                    <img src={photo.image_url} alt="" className="img-fluid" />
                                                </React.Fragment>
                                            )}
                                            </Slider>
                                        </div>: null}
                                    </div>
                                    <div className="col-lg-6 rtl-text">
                                        <div className="product-right">
                                            <h2> {product.product_name} </h2>
                                            <h3>{symbol}{product.price}</h3>
                                            {product.colors?
                                            <ul className="color-variant">
                                                {product.colors.map((vari, i) =>
                                                    <li style={{ 
                                                        borderRadius: '100%',
                                                        border: vari.color !== this.state.color ? '1px solid #ccc': '5px solid #999',
                                                        background: vari.color}} key={i} title={vari.color} onClick={() => this.setState({ color: vari.color })}></li>)
                                                }
                                            </ul>:''}
                                            <div className="border-product">
                                                <h6 className="product-title">product details</h6>
                                                <p>{product.shortDetails}</p>
                                            </div>
                                            <div className="product-description border-product">
                                                {product.sizes?
                                                <div className="size-box">
                                                    <ul>
                                                        {product.sizes.map(({ size }, i) => {
                                                            return (
                                                                <li key={i}>
                                                                    <a href="#" style={{
                                                                        borderRadius: '100%',
                                                                        border: size !== this.state.size ? '1px solid #ccc': '2px solid #999'
                                                                    }} onClick={e => {
                                                                        e.preventDefault()
                                                                        this.setState({ size })
                                                                    }}>{size}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>:''}
                                                <h6 className="product-title">quantity</h6>
                                                <div className="qty-box">
                                                    <div className="input-group">
                                                            <span className="input-group-prepend">
                                                            <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                                                                <i className="fa fa-angle-left"></i>
                                                            </button>
                                                            </span>
                                                        <input type="number" name="quantity" value={this.state.quantity}  onChange={this.changeQty} className="form-control input-number" />
                                                            <span className="input-group-prepend">
                                                            <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                                                            <i className="fa fa-angle-right"></i>
                                                            </button>
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-buttons">
                                                <button  className="btn btn-solid" onClick={() => onAddToCartClicked({ ...product, color, size}, this.state.quantity)} >add to cart</button>
                                                <Link to={`${process.env.PUBLIC_URL}/products/${product.id}`} className="btn btn-solid">view detail</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ProductItem;