import React, { Component } from "react";
import useForm from "react-valida-hook";
import { ToastContainer, toast } from "react-toastify";

import Breadcrumb from "../common/breadcrumb";

const { PUBLIC_URL } = process.env;
const styles = {
  width: "80%",
  color: "",
};
class Success extends Component {
  state = {};

  render() {
    return (
      <div>
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 right-login">
                <h3 className="text-center" style={{ color: "#ff4c3b" }}>
                  Success!!!
                </h3>
                <div className="theme-card authentication-right text-center">
                  <p>Please check your email address to validate your account</p>
                  <img src="https://i.ibb.co/bgJCHc6/undraw-success-factors-fay0-1.png" alt="undraw-success-factors-fay0-1" border="0" style={{ width: styles.width }} />
                  <div style={{ width: "100%" }}>
                    <a href={`${PUBLIC_URL}/login`} className="btn btn-solid">
                      Login
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Success;
