import {
    FETCH_WALLET_TRANSACTIONS,
  } from "../constants/ActionTypes.js";
  
  const initialState = {
    balance: 0,
    transactions: [],
  };
  
  export default function storeReducer(state = initialState, action) {
    switch (action.type) {  
      case FETCH_WALLET_TRANSACTIONS:
        return { ...state, transactions: action.payload };
  
      default:
        return state;
    }
  }