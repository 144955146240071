import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from "react";
import SpinLoader from "react-loader-spinner";

export default function Loader({
  type = "ThreeDots",
  color = "#ff4c3b",
  height = 100,
  width = 100,
  message,
  ...props
}) {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ flex: 1, flexDirection: "column" }}
      {...props}
    >
      <div
        style={{ flexDirection: "column" }}
        className="d-flex align-items-center justify-content-center"
      >
        <SpinLoader type={type} color={color} height={height} width={width} />
        {message ? <h4>{message}</h4> : ""}
      </div>
    </div>
  );
}
