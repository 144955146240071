import * as React from "react";
import store from "../../store";
import { getUserProfile } from "../../actions";

export function withAuthenticated(WrappedComponent, redirect = true) {
  class AuthenticatedComp extends React.Component {
    componentDidMount() {
      const redirectToLogin = () => {
        if (!redirect) return;
        this.props.history.push("/login");
      };

      if (localStorage.getItem("token")) {
        return store.dispatch(getUserProfile(redirectToLogin));
      }

      return redirectToLogin();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return AuthenticatedComp;
}
