import {
  SET_PROFILE,
  FETCH_ORDER_NOTIFICATIONS,
  FETCH_ORDER,
  UPDATE_WALLET_BALANCE
} from "../constants/ActionTypes";

const initialState = {
  profile: null,
  orderNotifications: [],
  orderDetails: {}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      const { profile } = action;

      return { ...state, profile };
    
    case UPDATE_WALLET_BALANCE:
      return { ...state, profile: { ...state.profile, wallet_balance: action.payload } };  

    case FETCH_ORDER_NOTIFICATIONS:
      const { orderNotifications } = action;

      return { ...state, orderNotifications };

    case FETCH_ORDER:
      const { order } = action;

      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          [order.id]: order
        }
      };

    default:
      return state;
  }
}
