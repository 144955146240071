import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { withAuthenticated } from "../HOCs/withAuthenticated";

class TermsAndConditons extends Component {
  
  render() {
    return (
      <div>
        <Breadcrumb title={"Terms and Conditions"} />

        {/*Dashboard section*/}
        <section className="faq-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <div>
                  <p className='text' style={{ lineHeight: '2' }}>Copyright © 2020 Timeshoppy.com. All rights reserved</p>

                  <p className='text' style={{ lineHeight: '2' }}>
                    Timeshoppy is an E-commerce platform that brings buyers and sellers together on a wide scale geared towards a global marketplace for all to trade.
                  </p>

                  <p style={{ lineHeight: '2' }}>
                    As a prepaid site, buyers are expected to complete their transactions as they await seller the required time space to deliver, an extension can be given on request by the seller or refund initiated once time lapses.
                  </p>
                  <p style={{ lineHeight: '2' }}>
                    As a buyer you are not to pay any additional cost after all charges had been paid for. The chat box opened between seller and buyer is strictly for either dispute, direction or any other information apart from cash. Please do not discuss cash in the chat box.
                    By accepting to this, users will be subjected to the terms and conditions.
                  </p>

                  <br />

                  <h5>Disclaimer</h5>
                  <p style={{ lineHeight: '2' }}>
                    Any additional payment through individual(s) on the chat box will be at user's risk. Users of the site 
                    should find out the tax laws of their country, state, district or region. All tax responsibility will be of the users.{" "}
                    <a href={process.env.PUBLIC_URL}>Timeshoppy.com</a> will not be liable to anyone or group of people on the platform who either
                    willingly or ignorantly invade tax where necessary.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withAuthenticated(TermsAndConditons, false);
