import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import useForm from "react-valida-hook";
import { bankDetailValidatorSchema } from "../../../utils/validators";
import { prepareSelectOptions } from "../../../utils";

export default function WithdrawalModal({ onSubmit, bankDetail, options }) {
  const [initialFormState, setInitialFormState] = useState({
    account_name: bankDetail.account_name || '',
    account_number: bankDetail.account_number || '',
    bank_id: bankDetail.bank_id || '',
  });

  console.log(bankDetail);

  useEffect(() => {
    setInitialFormState(bankDetail);
  }, [bankDetail]);

  let [formData, validation, validateForm, getData] = useForm(
    initialFormState,
    bankDetailValidatorSchema
  );

  let handleSubmit = e => {
    e.preventDefault();
    if (!validateForm()) return;

    let data = {
      ...getData(),
    };

    return onSubmit(data);
  };

  return (
      <form className="section-b-space px-3 py-4" onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <label>Account Name</label>
              <input
                type="text"
                className="form-control"
                id="account_name"
                name="account_name"
                {...formData.account_name.input}
                style={{ width: '100%', maxWidth: '100%' }}
              />
            </div>

            <div className="col-12 mb-3">
              <label>Account Number</label>
              <input
                type="text"
                className="form-control"
                id="account_number"
                name="account_number"
                {...formData.account_number.input}
                style={{ width: '100%', maxWidth: '100%' }}
              />
            </div>
            
            <div className="col-12 mb-3">
              <label>Bank</label>
              <select
                  className="form-control"
                  id="bank_id"
                  name="bank_id"
                  {...formData.bank_id.input}
              >
                {options.banks.map(bank => <option key={bank.id} value={bank.id}>{bank.name}</option>)}
              </select>
            </div>

            <div style={{ width: 'fit-content', marginRight: 'auto' }} className="col-12">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
  );
}
