/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  dropMessages,
  renderCustomComponent
} from "react-chat-widget";
import { Socket } from "react-socket-io";
import Modal from "react-responsive-modal";
import "react-chat-widget/lib/styles.css";
import OrderItem from "../common/orderItem";
import { WalletFundingForm } from "../common/walletFunding";
import Aside from '../common/aside/aside';
import Loader from "../common/loader";

import {
  updateUserProfile,
  fundWallet,
  fetchOrderNotifications,
  payForOrder,
  sendChatMessage,
  fetchChatMessages,
  updateOrder as updateOrderAction,
  extendDeliveryTime,
  confirmDelivery,
  getSingleOrder,
  requestRefund as requestRefundAction
} from "../../actions";

import Breadcrumb from "../common/breadcrumb";
// Higher Order Components
import { withAuthenticated } from "../HOCs/withAuthenticated";

let qs = require("qs");

const SET_STATE = "SET_STATE";

const initialState = {
  activeConversation: null,
  amountToFund: 0,
  isPaymentProcessing: false,
  isExtendingDeliveryTime: null,
  isAcceptingOrder: null,
  isDecliningOrder: null,
  activeTab: "active-orders",
  messages: {},
  openOrders: []
};

const populateResponses = ({ messages, userId, role = "Seller" }) => {
  let encounteredAuthorMessage = false;

  return (
    messages &&
    messages.map(({ user_id, message }, key) => {
      const senderIsAuthor = parseInt(user_id) === parseInt(userId);

      if (senderIsAuthor) {
        if (!encounteredAuthorMessage)
          renderCustomComponent(() => (
            <div className={`small text-muted w-100 text-right`}>You said:</div>
          ));

        encounteredAuthorMessage = true;
      } else {
        renderCustomComponent(() => (
          <div className={`small text-muted w-100 text-left`}>
            {`${role} said:`}
          </div>
        ));
      }

      return senderIsAuthor
        ? addUserMessage(message)
        : addResponseMessage(message);
    })
  );
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_STATE:
      return {
        ...state,
        ...payload
      };

    default: {
      throw new Error("Invalid action type");
    }
  }
}

function Orders({
  user,
  location,
  symbol,
  fundWallet,
  sendChatMessage,
  fetchChatMessages,
  fetchOrderNotifications,
  fetchSingleOrder,
  updateOrder,
  payForOrder,
  extendDeliveryTime,
  confirmDelivery,
  requestRefund
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    activeConversation,
    activeTab,
    amountToFund,
    isPaymentProcessing,
    isAcceptingOrder,
    isDecliningOrder,
    isRequestingRefund,
    isExtendingDeliveryTime,
    messages,
    openOrders
  } = state;
  const { orderNotifications = [], profile } = user;
  const { wallet_balance } = profile || {};

  const isDeclinedOrder = order => order.declined_at;
  const isDeliveredOrder = order => order.buyer_confirms_delivery_at;
  const messagesReducer = (prev, current) => ({
    ...prev,
    [current.order_id]: current.conversationMessage
  });

  const activeOrderNotifications = orderNotifications.filter(
    order => !isDeclinedOrder(order) && !isDeliveredOrder(order)
  );
  const declinedOrderNotifications = orderNotifications.filter(isDeclinedOrder);
  const completedOrderNotifications = orderNotifications.filter(
    order => !isDeclinedOrder(order) && isDeliveredOrder(order)
  );

  const updateState = payload => {
    dispatch({
      type: SET_STATE,
      payload
    });
  };

  const handleNewUserMessage = message => {
    sendChatMessage({
      message,
      order_id: activeConversation
    });
  };

  const handleAcceptOrder = ({ id, placementCode, total, payWith }) => {
    const amountToFund = total - wallet_balance;
    updateState({ isAcceptingOrder: id });

    if (payWith === "wallet") {
      return wallet_balance < total
        ? updateState({ amountToFund })
        : payForOrder(id, async () => {
            await fetchOrderNotifications();
            toast.success("Successfully completed order payment");
            updateState({ isAcceptingOrder: null });
            return this.props.history.push({
              pathname: "/order-payment-success",
              state: {
                orderCode: placementCode,
                payment: 1
              }
            });
          });
    } else {
      updateState({ isAcceptingOrder: id });
      fundWallet({
        amount: total,
        transaction_type_id: 2,
        redirect_url: `dashboard/orders?paidFor=${id}&orderCode=${placementCode}`
      });
    }

    updateState({ isAcceptingOrder: null });
  };

  const handleDeclineOrder = orderId => {
    updateState({ isDecliningOrder: orderId });
    updateOrder(
      {
        orderId,
        payload: {
          declined_at: new Date()
        }
      },
      () => {
        fetchOrderNotifications();
        updateState({ isDecliningOrder: null });
      }
    );
  };

  const handleActivateMessageBox = conversationId => {
    if (activeConversation !== conversationId) {
      fetchChatMessages(conversationId, msgs => {
        const chatMessages = msgs.reduce(messagesReducer, messages);
        updateState({
          messages: chatMessages
        });

        dropMessages();
        updateState({
          activeConversation: conversationId
        });

        populateResponses({
          messages: chatMessages[conversationId],
          userId: user.profile.id
        });
      });
    }

    toggleWidget();
  };

  const handleRequestRefund = async orderId => {
    updateState({
      isRequestingRefund: orderId
    });

    requestRefund(orderId, () => {
      updateState({
        isRequestingRefund: null
      });
      fetchSingleOrder(orderId);
    });
  };

  const handleExtendDeliveryTime = async orderId => {
    updateState({
      isExtendingDeliveryTime: orderId
    });
    await extendDeliveryTime(orderId, () => {
      updateState({
        isExtendingDeliveryTime: null
      });
      fetchSingleOrder(orderId);
    });
  };

  const handleConfirmDelivery = orderId => {
    updateState({
      isConfirmingDelivery: orderId
    });
    confirmDelivery(orderId, () => {
      fetchOrderNotifications();

      updateState({
        isExtendingDeliveryTime: null
      });
    });
  };

  const handleViewOrderDetails = orderId => {
    fetchSingleOrder(orderId, () => {
      updateState({
        openOrders: [...openOrders, orderId]
      });
    });
  };

  React.useEffect(() => {
    if (location.search.length) {
      const { paidFor, orderCode } = qs.parse(location.search.split("?")[1]);
      if (paidFor) {
        updateState({ isPaymentProcessing: true });
        payForOrder(paidFor, () => {
          fetchOrderNotifications();
          toast.success("Successfully completed payment");
          updateState({ isPaymentProcessing: false });
          return this.props.history.push({
            pathname: "/order-payment-success",
            state: {
              orderCode,
              payment: 1
            }
          });
        });
      }

      updateState({ isPaymentProcessing: false });
    }

    fetchOrderNotifications();
  }, [location]);

  const createOrderItems = (order, key) => {
    return (
      <OrderItem
        key={key}
        order={order}
        symbol={symbol}
        title={`${order.order_products[0].products[0].product_name}`}
        walletBalance={user.profile.wallet_balance}
        isOrderDetailsOpen={openOrders.includes(order.order_id)}
        isExtendingDeliveryTime={order.order_id === isExtendingDeliveryTime}
        isRequestingRefund={isRequestingRefund}
        isAcceptingOrder={order.order_id === isAcceptingOrder}
        isDecliningOrder={order.order_id === isDecliningOrder}
        onAcceptOrder={handleAcceptOrder}
        onDeclineOrder={handleDeclineOrder}
        onActivateMessageBox={handleActivateMessageBox}
        onExtendDeliveryTime={handleExtendDeliveryTime}
        onConfirmDelivery={handleConfirmDelivery}
        onRequestRefund={handleRequestRefund}
        onViewOrderDetails={handleViewOrderDetails}
        orderDetails={
          user.orderDetails && user.orderDetails.hasOwnProperty(order.order_id)
            ? user.orderDetails[order.order_id]
            : null
        }
      />
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My orders</title>
      </Helmet>

      <ToastContainer />
      <Breadcrumb parent={"Dashboard"} title="orders" />
      {/*Dashboard section*/}
      {user.profile && (
        <>
          <section className="section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <Aside />
                </div>
                <div className="col-lg-9">
                  <div className="dashboard-right">
                    <div className="dashboard">
                      <div className="page-title mb-4">
                        <h2>
                          Orders{" "}
                          {orderNotifications
                            ? `(${orderNotifications.length})`
                            : null}
                        </h2>
                      </div>
                      <div className="box-info">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="box">
                              <div className="box-content">
                                <nav>
                                  <div
                                    className="nav nav-tabs"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <button
                                      className={`nav-item nav-link ${activeTab ===
                                        "active-orders" && "active"}`}
                                      data-toggle="tab"
                                      role="tab"
                                      aria-controls="nav-activeOrders"
                                      aria-selected="true"
                                      onClick={() => {
                                        updateState({
                                          activeTab: "active-orders"
                                        });
                                      }}
                                    >
                                      Active Orders
                                    </button>
                                    <button
                                      className={`nav-item nav-link ${activeTab ===
                                        "completed-orders" && "active"}`}
                                      id="nav-declined-tab"
                                      data-toggle="tab"
                                      role="tab"
                                      aria-controls="nav-declined"
                                      aria-selected="false"
                                      onClick={() => {
                                        updateState({
                                          activeTab: "completed-orders"
                                        });
                                      }}
                                    >
                                      Completed Orders
                                    </button>
                                    <button
                                      className={`nav-item nav-link ${activeTab ===
                                        "declined-orders" && "active"}`}
                                      id="nav-declined-tab"
                                      data-toggle="tab"
                                      role="tab"
                                      aria-controls="nav-declined"
                                      aria-selected="false"
                                      onClick={() => {
                                        updateState({
                                          activeTab: "declined-orders"
                                        });
                                      }}
                                    >
                                      Declined Orders
                                    </button>
                                  </div>
                                </nav>
                                <div className="tab-content">
                                  <div
                                    className={`tab-pane ${activeTab ===
                                      "active-orders" && "show active"} fade`}
                                    role="tabpanel"
                                    aria-labelledby="nav-activeOrders-tab"
                                  >
                                    {activeOrderNotifications.length ? (
                                      activeOrderNotifications.map(
                                        createOrderItems
                                      )
                                    ) : (
                                      <p className="my-4">
                                        You don't have any active orders at the
                                        moment
                                      </p>
                                    )}
                                  </div>
                                  <div
                                    className={`tab-pane ${activeTab ===
                                      "declined-orders" && "show active"} fade`}
                                    role="tabpanel"
                                    aria-labelledby="nav-activeOrders-tab"
                                  >
                                    {declinedOrderNotifications.length ? (
                                      declinedOrderNotifications.map(
                                        createOrderItems
                                      )
                                    ) : (
                                      <p className="my-4">
                                        You don't have any declined orders at
                                        the moment
                                      </p>
                                    )}
                                  </div>
                                  <div
                                    className={`tab-pane ${activeTab ===
                                      "completed-orders" &&
                                      "show active"} fade`}
                                    role="tabpanel"
                                    aria-labelledby="nav-completedOrders-tab"
                                  >
                                    {completedOrderNotifications.length ? (
                                      completedOrderNotifications.map(
                                        createOrderItems
                                      )
                                    ) : (
                                      <p className="my-4">
                                        You don't have any completed orders at
                                        the moment
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Widget
            title={"Conversation with Seller"}
            subtitle="The admin is monitoring messages"
            handleNewUserMessage={handleNewUserMessage}
            senderPlaceHolder="Message here..."
          />
          <Modal
            open={isPaymentProcessing}
            onClose={() => updateState({ isPaymentProcessing: false })}
          >
            <Loader message="Your payment is being processed" />
          </Modal>
          {amountToFund ? (
            <WalletFundingForm
              isOpen={amountToFund > 0}
              amount={amountToFund}
              onClose={() => updateState({ amountToFund: 0 })}
              fundWallet={fundWallet}
              redirectUrl="dashboard/orders"
            />
          ) : null}
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    symbol: state.data.symbol,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserProfile: bindActionCreators(updateUserProfile, dispatch),
  updateOrder: bindActionCreators(updateOrderAction, dispatch),
  fetchSingleOrder: bindActionCreators(getSingleOrder, dispatch),
  fundWallet: bindActionCreators(fundWallet, dispatch),
  fetchOrderNotifications: bindActionCreators(
    fetchOrderNotifications,
    dispatch
  ),
  fetchChatMessages: bindActionCreators(fetchChatMessages, dispatch),
  sendChatMessage: bindActionCreators(sendChatMessage, dispatch),
  payForOrder: bindActionCreators(payForOrder, dispatch),
  extendDeliveryTime: bindActionCreators(extendDeliveryTime, dispatch),
  confirmDelivery: bindActionCreators(confirmDelivery, dispatch),
  requestRefund: bindActionCreators(requestRefundAction, dispatch)
});

export default withAuthenticated(
  connect(mapStateToProps, mapDispatchToProps)(Orders),
  false
);
