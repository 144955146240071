import React, { Component } from "react";
import { withAuthenticated } from "../HOCs/withAuthenticated";
const { PUBLIC_URL } = process.env;

class OrderSuccess extends Component {
  render() {
    const { payment, items, symbol, address, orderSubtotal, orderTotal } =
      this.props.location.state || {};

    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    var current = new Date();
    var next5days = new Date(Date.now() + 5 * 86400000);
    let CheckDate = current.toLocaleDateString("en-US", options).toString();
    let deliveryDate = next5days
      .toLocaleDateString("en-US", options)
      .toString();

    return payment ? (
      <div>
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="success-text">
                  <i className="fa fa-check-circle" aria-hidden="true"></i>
                  <h2>Your order has been placed</h2>
                  <p>
                    You may pay for the order once the seller agrees to deliver
                  </p>
                  <a
                    href={`${PUBLIC_URL}/dashboard/orders`}
                    className="btn btn-dark"
                  >
                    See your order
                  </a>
                  <p>
                    {/* Transaction ID:{" "} */}
                    {/* {payment.paymentID ? payment.paymentID : payment.id} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="product-order">
                  <h3>Your order details</h3>
                  {items.map((item, index) => {
                    return (
                      <div className="row product-order-detail" key={index}>
                        <div className="col-3">
                          <img
                            src={item.main_product_images[0].image_url}
                            alt=""
                            className="img-fluid"
                          />
                          {item.color && item.color.length ? (
                            <div
                              className="border mt-2 rounded-circle"
                              style={{
                                display: "inline-block",
                                width: "2rem",
                                height: "2rem",
                                backgroundColor: item.color
                              }}
                            ></div>
                          ) : null}
                        </div>
                        <div className="col-3 order_detail">
                          <div>
                            <h4>product name</h4>
                            <h5>{item.product_name}</h5>
                          </div>
                        </div>
                        <div className="col-3 order_detail">
                          <div>
                            <h4>quantity</h4>
                            <h5>{item.qty}</h5>
                          </div>
                        </div>
                        <div className="col-3 order_detail">
                          <div>
                            <h4>price</h4>
                            <h5>
                              {symbol}
                              {item.sum}
                            </h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="total-sec">
                    <ul>
                      <li>
                        subtotal{" "}
                        <span>
                          {symbol}
                          {orderSubtotal}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="final-total">
                    <h3>
                      total{" "}
                      <span>
                        {symbol}
                        {orderTotal}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row order-success-sec mb-3">
                  <div className="col-sm-12">
                    <h4>Summary</h4>
                    <ul className="order-detail">
                      <li>
                        <b>Order Date:</b> {CheckDate}
                      </li>
                      <li>
                        <b>Order Total</b>: {symbol}
                        {orderTotal}
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <h4>Shipping address</h4>
                    <ul className="order-detail">
                      <li>{address}</li>
                    </ul>
                  </div>

                  <div className="col-md-12 sr-only">
                    <div className="delivery-sec">
                      <h4>Expected date of delivery</h4>
                      <h2>{deliveryDate}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ) : (
      <section className="p-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="error-section">
                <h1>404</h1>
                <h2>page not found</h2>
                <a href="index.html" className="btn btn-solid">
                  back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withAuthenticated(OrderSuccess, false);
