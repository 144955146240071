import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { withAuthenticated } from "../HOCs/withAuthenticated";

class Faq extends Component {
  state = {
    faqList: [
      {
        question: "What is TIMESHOPPY?",
        answer: "Timeshoppy is a site that brings buyers and sellers together.",
      },
      {
        question: "How much does it cost to post products?",
        answer: "Registering and posting products is totally free.",
      },
      {
        question: "How long does my product stay on the site?",
        answer:
          "So long as a seller you update your inventory every 7 days at least, products on your store remains visibly active.",
      },
      {
        question: "Who pays Tax?",
        answer:
          "As a seller, please inquire the tax laws in your region either if you can be exempted or mandated to pay. Either way, seller takes full responsibility of this.",
      },
      {
        question: "How many days does it take to get my product(s)",
        answer:
          "It takes 48 hours from the moment of payment by the buyer. In case seller has a delay in delivering goods within this space of time, he/she can chat up the buyer for a 24 hours extension before the transaction is finally closed.",
      },
      {
        question: "In place a delivery is not made?",
        answer:
          "Buyer will get a refund into his wallet automatically, which will take 24 hours before he can transfer to his desired bank account.",
      },
      {
        question: "Defective item?",
        answer:
          "Buyer should check or test items in presence of seller or representative before clicking on the CONFIRM button.",
      },
      {
        question: "I delivered but not CONFIRMED by buyer?",
        answer:
          "Please drop a message in the chat box for buyer and call. The site admin will take it up from there.",
      },
      {
        question: "Product not processing?",
        answer:
          "For now the site is designed to only tend to product from a particular store. Purchase can not be made from multiple stores.",
      },
      {
        question: "Chat box?",
        answer: (
          <Fragment>
            As a seller you are not allowed to discuss any extra payment(s) with
            buyer in the chat box. All orders are prepaid.
            <br /> <br />
            Buyers should report any of such seller that requires any additional
            fee either for shipment or delivery as the case may be. After paying
            the total cost for a placed order, no payment should be entertained
            on the chat box, it is strictly for delivery or dispute purpose.
          </Fragment>
        ),
      },
    ],
  };
  render() {
    return (
      <div>
        <Breadcrumb title={"Faq"} />

        {/*Dashboard section*/}
        <section className="faq-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="accordion theme-accordion"
                  id="accordionExample"
                >
                  {this.state.faqList.map((faq, key) => (
                    <div className="card" key={key}>
                      <div className="card-header" id={`heading-${key}`}>
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse-${key}`}
                            aria-expanded="true"
                            aria-controls={`#collapse-${key}`}
                          >
                            {faq.question}
                          </button>
                        </h5>
                      </div>

                      <div
                        id={`collapse-${key}`}
                        className="collapse show"
                        aria-labelledby={`heading-${key}`}
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>{faq.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withAuthenticated(Faq, false);
